import { memo, forwardRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import isUndefined from 'lodash/isUndefined';
import { type AxiosResponse } from 'axios';
import { useIntl, FormattedMessage } from 'react-intl';
// Material UI imports
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
// Material Icon imports
import LoginIcon from '@mui/icons-material/Login';
// Skillmore UI Components
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
// local imports
import { axiosInstance as axios, getApiAdminImpersonation } from '../config/api';
import { PATH_HOME } from '../config/paths';
import { getAuthToken, savePath, setAuthToken } from '../helpers/storage';
import { getRequestHeaders } from '../helpers/context';

const iconBtnSx = { ml: -1, mr: -2.5 };

type ImpersonateButtonProps = {
  code: string;
  large?: boolean;
  disabled?: boolean;
  onPending?: (pending: boolean) => void;
  // for Storybook only
  testPending?: boolean;
}

const ImpersonateButtonPropTypes = {
  // attributes
  code: PropTypes.string.isRequired,
  large: PropTypes.bool,
  disabled: PropTypes.bool,
  onPending: PropTypes.func,
  // for Storybook only
  testPending: PropTypes.bool
};

const ImpersonateButton = forwardRef<HTMLButtonElement, ImpersonateButtonProps>(({
  code,
  large = false,
  disabled: parentDisabled = false,
  onPending,
  testPending
}, ref) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [pending, setPending] = useState(false);

  const handleClick = useCallback(async () => {
    // clear error message
    setErrorMessage(undefined);
    // set pending state
    setPending(true);
    onPending?.(true);
    try {
      const adminToken = getAuthToken();
      const { status, data } = await axios.request<{}, AxiosResponse<{
        impersonation?: boolean;
        token?: string;
        error_message?: string;
      }>>({
        method: 'POST',
        url: getApiAdminImpersonation(code),
        headers: getRequestHeaders(adminToken)
      }) || {};
      if (status < 200 || status > 201 || !data || !data?.impersonation || !data?.token) {
        setErrorMessage(data?.error_message || 'admin.error.impersonation');
        // clear pending state
        setPending(false);
        onPending?.(false);
        return;
      }

      // set admin auth token (if not already)
      if (!getAuthToken(true)) setAuthToken(adminToken, true);
      // set new impersonation token
      setAuthToken(data.token);
      // clear current saved path
      savePath('');
      // clear pending state
      setPending(false);
      onPending?.(false);
      // redirect to Home screen
      window.location.assign(PATH_HOME);
    } catch (_err) {
      setErrorMessage('admin.error.impersonation');
      setPending(false);
      onPending?.(false);
    }
  }, [code, onPending]);

  const disabled = parentDisabled || pending || testPending || !getAuthToken() ? true : undefined;

  const icon = pending || testPending ? (
    <CircularProgress color="inherit" size="1em"/>
  ) : (
    <LoginIcon color="inherit" fontSize="medium"/>
  );

  return (
    <>
      {large ? (
        <Button
            ref={ref}
            color="secondary"
            variant="text"
            disabled={disabled}
            onClick={handleClick}
            startIcon={icon}
        >
          <FormattedMessage id="admin.button.impersonate"/>
        </Button>
      ) : (
        <IconButton
            ref={ref}
            aria-label={formatMessage({ id: 'admin.button.impersonate' })}
            color="primary"
            size="small"
            disabled={disabled}
            onClick={handleClick}
            sx={iconBtnSx}
        >
          {icon}
        </IconButton>
      )}
      {isUndefined(errorMessage) ? undefined : (
        <ActionFailedAlert
            message={errorMessage || 'admin.error.impersonation'}
            open={Boolean(errorMessage)}
        />
      )}
    </>
  );
});

ImpersonateButton.displayName = 'ImpersonateButton';

ImpersonateButton.propTypes = ImpersonateButtonPropTypes;

export default memo(ImpersonateButton);
