import { memo, useCallback, useMemo, type FunctionComponent, type ReactNode } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import size from 'lodash/size';
import isNil from 'lodash/isNil';
import { Link as RouterLink } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
// Material Icon imports
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
// Skillmore UI Components
import { type ExportFormat } from '@empathco/ui-components/src/models/exportFormat';
import { bold } from '@empathco/ui-components/src/helpers/intl';
import useConfirmationDialog from '@empathco/ui-components/src/hooks/useConfirmationDialog';
import ExportButton, { type ExportParams } from '@empathco/ui-components/src/elements/ExportButton';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
import ConfirmDialog from '@empathco/ui-components/src/elements/ConfirmDialog';
// local imports
import useEditTitle from '../hooks/useEditTitle';
import useExport from '../hooks/useExport';
// SCSS imports
import { outlinedRoot, outlined, root, input } from './ReviewBar.module.scss';

const classes = { root: outlinedRoot, input };
const inputLabelProps = { classes: { root, outlined } };

type ReviewBarProps = {
  titleLabel: string;
  title?: string;
  link?: string;
  avatar?: ReactNode | ReactNode[];
  status?: ReactNode | ReactNode[] | null;
  pending?: boolean;
  disabled?: boolean;
  editLabel: string;
  onRename: (title?: string) => void;
  renameLabel: string;
  renamePending?: boolean | null;
  renameFailed?: boolean | null;
  renameError: string;
  onDelete: () => void;
  deleteLabel: string;
  deleteTitle?: string;
  deleteQuestion: string;
  deletePending?: boolean | null;
  deleteFailed?: boolean | null;
  deleteError: string;
  exportParams?: ExportParams | null;
  exportEndpoint?: string;
  onExport?: (format: ExportFormat, title: string) => Promise<number | boolean> | null;
  exportDisabled?: boolean;
}

const ReviewBarPropTypes = {
  // attributes
  titleLabel: PropTypes.string.isRequired,
  title: PropTypes.string,
  link: PropTypes.string,
  avatar: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]) as Validator<ReactNode | ReactNode[]>,
  status: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]) as Validator<ReactNode | ReactNode[]>,
  pending: PropTypes.bool,
  disabled: PropTypes.bool,
  editLabel: PropTypes.string.isRequired,
  onRename: PropTypes.func.isRequired,
  renameLabel: PropTypes.string.isRequired,
  renamePending: PropTypes.bool,
  renameFailed: PropTypes.bool,
  renameError: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  deleteLabel: PropTypes.string.isRequired,
  deleteTitle: PropTypes.string,
  deleteQuestion: PropTypes.string.isRequired,
  deletePending: PropTypes.bool,
  deleteFailed: PropTypes.bool,
  deleteError: PropTypes.string.isRequired,
  exportParams: PropTypes.object as Validator<ExportParams>,
  exportEndpoint: PropTypes.string,
  onExport: PropTypes.func,
  exportDisabled: PropTypes.bool
};

// eslint-disable-next-line complexity
const ReviewBar: FunctionComponent<ReviewBarProps> = ({
  titleLabel,
  title: parentTitle,
  link,
  avatar,
  status,
  pending = false,
  disabled: disabledParent = false,
  editLabel,
  onRename,
  renameLabel,
  renamePending = false,
  renameFailed = false,
  renameError,
  onDelete,
  deleteLabel,
  deleteTitle,
  deleteQuestion,
  deletePending = false,
  deleteFailed = false,
  deleteError,
  exportParams,
  exportEndpoint,
  onExport,
  exportDisabled = false
}) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();

  const titleText = useMemo(() => formatMessage({ id: titleLabel }), [titleLabel, formatMessage]);

  const {
    inputRef,
    title,
    editing,
    handleRename,
    handleTitleChange,
    handleStartEditing,
    handleSaveTitle,
    handleKeys
  } = useEditTitle({
    parentTitle,
    renameFailed,
    updateTitle: onRename
  });

  const inputProps = useMemo(() => ({
    classes,
    onKeyDown: handleKeys,
    [pending ? 'startAdornment' : 'endAdornment']: ((pending || renamePending) && (
      <InputAdornment position={pending ? 'start' : 'end'}>
        <Box color={pending ? 'action.disabled' : 'text.disabled'} component="span">
          <CircularProgress size={28} color="inherit"/>
        </Box>
      </InputAdornment>
    )) || undefined
  }), [handleKeys, pending, renamePending]);

  const dialogValues = useMemo(() => ({ title, bold }), [title]);

  const {
    confirmOpen,
    confirmMounted,
    handleAction: handleDelete,
    handleCancel,
    handleExited,
    handleConfirm
  } = useConfirmationDialog(onDelete);

  const getExport = useCallback((format: ExportFormat, _token: string) => {
    if (exportDisabled || !title || !onExport) return null;
    return onExport(format, title);
  }, [exportDisabled, title, onExport]);

  const exp = useExport(getExport);

  const disabled = disabledParent || renamePending || deletePending ? true : undefined;

  return (
    <>
      <Box
          flexGrow={1}
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="flex-start"
          px={2}
          pt={1}
          pb={status ? undefined : 1}
          mb={status ? -1 : undefined}
      >
        {avatar}
        <Box
            display="flex"
            flexGrow={1}
            pl={avatar ? 0.25 : undefined}
            pr={2}
            alignItems="center"
            justifyContent="flex-start"
        >
          <TextField
              inputRef={inputRef}
              variant="outlined"
              label={pending || size(title) >= 1 ? undefined : titleText}
              margin="dense"
              fullWidth
              value={isNil(title) ? '' : title}
              onChange={handleTitleChange}
              onFocus={handleStartEditing}
              onBlur={handleSaveTitle}
              disabled={disabled}
              InputProps={inputProps}
              InputLabelProps={inputLabelProps}
          />
        </Box>
        {link ? (
          <Box pr={1.25} display="flex" justifyContent="flex-end">
            <Button
                variant="text"
                color="primary"
                startIcon={<EditIcon/>}
                component={RouterLink}
                to={link}
                disabled={disabled || size(link) < 1}
            >
              <FormattedMessage id={editLabel}/>
            </Button>
          </Box>
        ) : undefined}
        <Box pr={1.25} display="flex" justifyContent="flex-end">
          <Button
              variant="text"
              color="primary"
              startIcon={<EditIcon/>}
              onClick={handleRename}
              disabled={disabled || editing}
          >
            <FormattedMessage id={renameLabel}/>
          </Button>
        </Box>
        <Box pr={1.25} display="flex" justifyContent="flex-end">
          <Button
              variant="text"
              color="primary"
              startIcon={deletePending ? <CircularProgress size={18} color="inherit"/> : <DeleteIcon/>}
              onClick={handleDelete}
              disabled={disabled}
          >
            <FormattedMessage id={deleteLabel}/>
          </Button>
        </Box>
        <Box pr={1.25} display="flex" justifyContent="flex-end">
          <ExportButton
              pending={onExport ? exp.failed === false : undefined}
              disabled={disabled || !title || exportDisabled || (
                onExport && (exp.failed === false || !exp.enabled)
              )}
              params={onExport ? undefined : exportParams}
              endpoint={onExport ? undefined : exportEndpoint}
              onExport={onExport ? exp.handleExport : undefined}
          />
        </Box>
      </Box>
      {status}
      {title && confirmMounted ? (
        <ConfirmDialog
            open={confirmOpen}
            title={deleteTitle || deleteQuestion}
            text={deleteTitle ? deleteQuestion : undefined}
            withExtraPadding={!deleteTitle}
            withCancelButton
            values={dialogValues}
            onCancel={handleCancel}
            onConfirm={handleConfirm}
            onExited={handleExited}
        />
      ) : undefined}
      <ActionFailedAlert
          message={renameError}
          open={renameFailed}
      />
      <ActionFailedAlert
          message={deleteError}
          open={deleteFailed}
      />
    </>
  );
};

ReviewBar.propTypes = ReviewBarPropTypes;

export default memo(ReviewBar);
