/* eslint-disable max-lines */
import {
  memo, useCallback, useEffect, useLayoutEffect, useMemo, useState,
  type FunctionComponent, type MouseEvent, type Ref, type Component
} from 'react';
import PropTypes from 'prop-types';
import size from 'lodash/size';
import map from 'lodash/map';
import head from 'lodash/head';
import find from 'lodash/find';
import union from 'lodash/union';
import unionBy from 'lodash/unionBy';
import includes from 'lodash/includes';
import transform from 'lodash/transform';
import xor from 'lodash/xor';
import tail from 'lodash/tail';
import { useIntl, FormattedMessage } from 'react-intl';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { useDrop } from 'react-dnd';
// Material UI imports
import Box, { type BoxProps } from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
// Material Icon imports
import StarRounded from '@mui/icons-material/StarRounded';
// Skillmore UI Components
import { ExportFormat } from '@empathco/ui-components/src/models/exportFormat';
import { fontWeightMedium } from '@empathco/ui-components/src/styles/themeOptions';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
import useMutationMethod from '@empathco/ui-components/src/hooks/useMutationMethod';
import useDnD, { type DnDExtraIds, type DnDExtraSetIds } from '@empathco/ui-components/src/hooks/useDnD';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import CardFooter from '@empathco/ui-components/src/elements/CardFooter';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
import ActionSucceededMessage from '@empathco/ui-components/src/elements/ActionSucceededMessage';
import FetchFailedAlert from '@empathco/ui-components/src/elements/FetchFailedAlert';
import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
import InfoButton from '@empathco/ui-components/src/elements/InfoButton';
import ExportButton from '@empathco/ui-components/src/elements/ExportButton';
import FilterSelector from '@empathco/ui-components/src/elements/FilterSelector';
import AddSkillPopover from '@empathco/ui-components/src/widgets/AddSkillPopover';
import ConfirmDialog from '@empathco/ui-components/src/elements/ConfirmDialog';
// local imports
import { ORGS_QUERY } from '../graphql/Orgs';
import { HR_IN_DEMAND_SKILLS_QUERY } from '../graphql/HRInDemandSkills';
import { SUGGESTED_IN_DEMAND_QUERY } from '../graphql/SuggestedInDemandSkills';
import { UPDATE_IN_DEMAND_SKILLS } from '../graphql/UpdateInDemandSkills';
import {
  Org, OrgSkill, OrgSkillRoleType,
  OrgsDocument, HRInDemandSkillsDocument, SuggestedInDemandSkillsDocument, UpdateInDemandSkillsDocument
} from '../graphql/types';
import { ILookupSkill, LookupItem } from '../models/lookupItem';
import { DraggableType } from '../constants/draggableTypes';
import { MAX_INDEMAND_SKILLS, MAX_SUGGESTED_INDEMAND_SKILLS } from '../config/params';
import { LOOKUP_OPTIONS } from '../helpers/graphql';
import useExportInDemandSkills from '../hooks/useExportInDemandSkills';
import useExport from '../hooks/useExport';
import SkillSearch, { SkillSearchProps } from '../widgets/SkillSearch';
import TeamSelectedSkills from '../widgets/TeamSelectedSkills';
import TeamSkills from '../widgets/TeamSkills';
// SCSS imports
import { filters, modifiedBorder, sectionHeader } from './InDemandSkillsEditor.module.scss';

type Skill = Omit<OrgSkill, '__typename'>;

const EMPTY_SKILLS = [] as Skill[];

const highlightIconSx = { mr: '0.5rem' };

type InDemandSkillsEditorProps = {
  testUpdate?: boolean | null;
}

const InDemandSkillsEditorPropTypes = {
  testUpdate: PropTypes.bool
};

// eslint-disable-next-line complexity, max-statements, max-lines-per-function
const InDemandSkillsEditor: FunctionComponent<InDemandSkillsEditorProps> = ({
  testUpdate
}) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();
  const { exportInDemandSkills, IN_DEMAND_SKILLS_CSV_COLUMNS } = useExportInDemandSkills();

  const [org, setOrg] = useState(0);

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmMounted, setConfirmMounted] = useState(false);

  // orgs
  const { pending: orgsPending, failed: orgsFailed, results: orgs } = useQueryCounted({
    data: undefined as unknown as Org,
    key: 'orgs',
    query: useQuery(ORGS_QUERY as typeof OrgsDocument, LOOKUP_OPTIONS)
  });

  useLayoutEffect(() => {
    setOrg((prevOrg) => prevOrg === 0 ? head(orgs)?.id || 0 : prevOrg);
  }, [orgs]);

  const msgValues = useMemo(() => ({ org: find(orgs, ['id', org])?.title || '' }), [org, orgs]);

  // org in-demand skills
  const { query: getInDemand, pending: inDemandPending, failed: inDemandFailed, results: inDemand } = useQueryCounted({
    data: undefined as unknown as OrgSkill,
    key: 'hrInDemandSkills',
    lazyQuery: useLazyQuery(HR_IN_DEMAND_SKILLS_QUERY as typeof HRInDemandSkillsDocument)
  });

  // org suggested in-demand skills
  const { query: getSuggested, pending: suggestedPending, failed: suggestedFailed, results: suggested } = useQueryCounted({
    data: undefined as unknown as OrgSkill,
    key: 'suggestedInDemandSkills',
    lazyQuery: useLazyQuery(SUGGESTED_IN_DEMAND_QUERY as typeof SuggestedInDemandSkillsDocument)
  });

  const { everybodyIds, employeeIds, managerIds } = useMemo(() => transform(inDemand || [], ({
    everybodyIds: evrbdIds, employeeIds: emplIds, managerIds: mgrIds
  }, { id, role_type, deleted_at }) => {
    if (deleted_at) return;
    if (role_type === OrgSkillRoleType.employee) emplIds.push(id);
    else if (role_type === OrgSkillRoleType.manager) mgrIds.push(id);
    else evrbdIds.push(id);
  }, {
    everybodyIds: [] as number[],
    employeeIds: [] as number[],
    managerIds: [] as number[]
  }), [inDemand]);

  const [skillIds, setSkillIds] = useState(everybodyIds);
  const [employeeSkillIds, setEmployeeSkillIds] = useState(employeeIds);
  const [managerSkillIds, setManagerSkillIds] = useState(managerIds);

  useLayoutEffect(() => {
    setSkillIds(testUpdate === null ? tail(everybodyIds) : everybodyIds);
    setEmployeeSkillIds(employeeIds);
    setManagerSkillIds(managerIds);
  }, [everybodyIds, employeeIds, managerIds, testUpdate]);

  const allSelectedIds = useMemo(() => union(skillIds, employeeSkillIds, managerSkillIds),
    [skillIds, employeeSkillIds, managerSkillIds]);
  const extraIds: DnDExtraIds[] = useMemo(() => [employeeSkillIds, managerSkillIds], [employeeSkillIds, managerSkillIds]);
  const extraSetIds: DnDExtraSetIds[] = useMemo(() => [setEmployeeSkillIds, setManagerSkillIds], []);

  const [allSkills, setAllSkills] = useState<Skill[]>();
  useLayoutEffect(() => {
    if (!inDemandPending) {
      setAllSkills((prevSkills) => unionBy(
        inDemand || [],
        (!suggestedPending && suggested) || prevSkills || [],
        'id'
      ));
    }
  }, [inDemand, suggested, inDemandPending, suggestedPending]);

  const [initialSkills, initialSkillIds] = useMemo(() => {
    if (inDemandPending || !inDemand || suggestedPending || !suggested) return [undefined, undefined];
    const initSkills = unionBy(inDemand, suggested, 'id');
    return [initSkills, map(initSkills, 'id')];
  }, [inDemandPending, suggestedPending, inDemand, suggested]);

  const handleSelectionChange = useCallback((ids: number[], extraIndex?: number) => {
    (
      (extraIndex === 0 && setEmployeeSkillIds) ||
      (extraIndex === 1 && setManagerSkillIds) ||
      setSkillIds
    )(ids);
  }, []);

  const onReset = useCallback(() => {
    setSkillIds(everybodyIds);
    setEmployeeSkillIds(employeeIds);
    setManagerSkillIds(managerIds);
    if (initialSkills) setAllSkills([...initialSkills]);
  }, [everybodyIds, employeeIds, managerIds, initialSkills]);

  // Add Skill dialog
  const [anchorAddBtn, setAnchorAddBtn] = useState<HTMLButtonElement | null>(null);
  const handleAddOpen = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => event && setAnchorAddBtn(event.currentTarget), []);
  const handleAddClose = useCallback(() => setAnchorAddBtn(null), []);

  useEffect(() => {
    if (org) {
      getInDemand?.({ variables: { org_id: org } });
      getSuggested?.({ variables: { org_id: org, limit: MAX_SUGGESTED_INDEMAND_SKILLS } });
    }
  }, [org, getInDemand, getSuggested]);

  const { mutate: updateSkills, loading: updatePending, failed: updateFailed, succeeded: updateSucceeded } = useMutationMethod({
    key: 'updateInDemandSkills',
    mutation: useMutation(UPDATE_IN_DEMAND_SKILLS as typeof UpdateInDemandSkillsDocument)
  });

  const handleConfirm = useCallback(() => {
    setConfirmOpen(false);
    if (org && size(skillIds) >= 1) updateSkills({
      variables: { org_id: org, input: {
        skill_ids: [...skillIds, ...employeeSkillIds, ...managerSkillIds],
        role_types: [
          ...map(skillIds, () => OrgSkillRoleType.all),
          ...map(employeeSkillIds, () => OrgSkillRoleType.employee),
          ...map(managerSkillIds, () => OrgSkillRoleType.manager)
        ]
      } },
      update: (cache) => {
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'hrInDemandSkills' });
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'suggestedInDemandSkills' });
      }
    });
  }, [org, skillIds, employeeSkillIds, managerSkillIds, updateSkills]);

  const handleCancel = useCallback(() => setConfirmOpen(false), []);
  const handleExited = useCallback(() => setConfirmMounted(false), []);

  const handleUpdate = useCallback(() => {
    setConfirmOpen(true);
    setConfirmMounted(true);
  }, []);

  // for Storybook & Jest-snapshots testing only
  useEffect(() => {
    if (testUpdate && org) updateSkills({ variables: { org_id: org, input: { skill_ids: [], role_types: [] } } });
  }, [testUpdate, org, updateSkills]);

  const disabled = orgsPending || inDemandPending || suggestedPending || updatePending || !allSkills;

  const {
    items: skills,
    availableIds,
    addedIds,
    addItem,
    deleteItem,
    unselectItem,
    resetItems,
    // Drag and Drop state
    drop,
    isActive,
    selectedDrop,
    getDropArgs
  } = useDnD<Skill, DraggableType>({
    accept: 'skill',
    allItems: allSkills || EMPTY_SKILLS,
    ids: skillIds,
    setIds: setSkillIds,
    onSelectionChange: handleSelectionChange,
    onReset,
    disabled,
    manageAddedItems: true,
    updateIdsOnDelete: true,
    extraIds,
    extraSetIds
  });

  const employeeDropArg = useMemo(() => getDropArgs(false, 0), [getDropArgs]);
  const managerDropArg = useMemo(() => getDropArgs(false, 1), [getDropArgs]);

  const [{ isOver: isEmployeeActive }, employeeDrop] = useDrop(employeeDropArg);
  const [{ isOver: isManagerActive }, managerDrop] = useDrop(managerDropArg);

  const ariaLabelSuggested = useMemo(() => formatMessage({ id: 'hr.in_demand_skills.suggested' }), [formatMessage]);
  const skillSearchProps = useMemo(() => ({
    renderOption: ({ id, name, title }: ILookupSkill) => {
      const isHighlighted = Boolean(skills?.[id]);
      const label = name || title || '';
      return (
        <Box
            display="flex"
            alignItems="center"
            color={isHighlighted ? 'secondary.text' : undefined}
            fontWeight={isHighlighted ? fontWeightMedium : undefined}
            pl={isHighlighted ? undefined : '1.5rem'}
        >
          {isHighlighted ? (
            <StarRounded color="secondary" fontSize="inherit" sx={highlightIconSx} aria-label={ariaLabelSuggested}/>
          ) : undefined}
          {label}
        </Box>
      );
    }
  } as Partial<SkillSearchProps>), [skills, ariaLabelSuggested]);

  const modified = useMemo(() =>
    size(xor(skillIds, everybodyIds)) >= 1 ||
    size(xor(employeeSkillIds, employeeIds)) >= 1 ||
    size(xor(managerSkillIds, managerIds)) >= 1 || (
      Boolean(initialSkillIds && skills) && size(xor(initialSkillIds, map(skills, 'id'))) >= 1
    ),
    [skillIds, employeeSkillIds, managerSkillIds, everybodyIds, employeeIds, managerIds, initialSkillIds, skills]);

  const handleAddSkill = useCallback((skl?: LookupItem | null) => {
    if (skl) {
      if (!skills?.[skl.id]) addItem(skl as Skill);
      if (skl.id) setSkillIds((prevIds) => union(prevIds, [skl.id]));
    }
    setAnchorAddBtn(null);
  }, [addItem, skills]);

  const handleDeleteSkill = useCallback((id: number) => {
    (includes(addedIds, id) ? deleteItem : unselectItem)(id);
  }, [addedIds, deleteItem, unselectItem]);

  const getExport = useCallback((format: ExportFormat, _token: string) => {
    if (!skills || size(skills) < 1 || size(skillIds) < 1 || !orgs || !org) return null;
    const { title } = find(orgs, ['id', org]) || {};
    if (!title) return null;
    return exportInDemandSkills(
      format,
      title,
      map(IN_DEMAND_SKILLS_CSV_COLUMNS, (id) => formatMessage({ id })),
      [
        ...map(skillIds, (id) => ({ ...skills[id], role_type: OrgSkillRoleType.all })),
        ...map(employeeSkillIds, (id) => ({ ...skills[id], role_type: OrgSkillRoleType.employee })),
        ...map(managerSkillIds, (id) => ({ ...skills[id], role_type: OrgSkillRoleType.manager }))
      ],
      modified
    );
  }, [
    skills, skillIds, employeeSkillIds, managerSkillIds, modified, orgs, org, formatMessage,
    IN_DEMAND_SKILLS_CSV_COLUMNS, exportInDemandSkills
  ]);

  const exp = useExport(getExport);

  const everybodyWarningValues = useMemo(() => {
    const excess = size(skillIds) - MAX_INDEMAND_SKILLS;
    return excess >= 1 ? {
      max: MAX_INDEMAND_SKILLS,
      excess
    } : undefined;
  }, [skillIds]);
  const employeeWarningValues = useMemo(() => {
    const excess = size(employeeSkillIds) - MAX_INDEMAND_SKILLS;
    return excess >= 1 ? {
      max: MAX_INDEMAND_SKILLS,
      excess
    } : undefined;
  }, [employeeSkillIds]);
  const managerWarningValues = useMemo(() => {
    const excess = size(managerSkillIds) - MAX_INDEMAND_SKILLS;
    return excess >= 1 ? {
      max: MAX_INDEMAND_SKILLS,
      excess
    } : undefined;
  }, [managerSkillIds]);

  return (
    <>
      {!orgsFailed && !orgsPending && orgs ? (
        <CardSection flex className={filters}>
          {org ? (
            <Box pr={1.25} pb={1} flexGrow={4} display="flex" alignItems="center" justifyContent="flex-start">
              <FilterSelector
                  type="org"
                  required
                  choices={orgs}
                  value={org}
                  onChange={setOrg}
                  disabled={disabled || size(orgs) < 1}
              />
            </Box>
          ) : undefined}
          {/* TODO: add 'Use External Analytics Trends' switch - when the data on backend is ready */}
          <Box pb={1} px={1} display="flex" alignItems="center" justifyContent="center">
            <BoxTypography variant="subtitle2" color="info.caption" fontStyle="italic">
              <FormattedMessage id="hr.in_demand_skills.info"/>
            </BoxTypography>
            <InfoButton
                help="hr.in_demand_skills.info.tooltip"
                placement="top"
                small
            />
          </Box>
          <Box pb={1} flexGrow={1} display="flex" alignItems="center" justifyContent="flex-end">
            <ExportButton
                pending={exp.failed === false}
                disabled={disabled || !orgs || !org || !allSkills || size(skillIds) < 1 || exp.failed === false || !exp.enabled}
                onExport={exp.handleExport}
            />
          </Box>
        </CardSection>
      ) : undefined}
      {((inDemandFailed || orgsFailed) && <FetchFailedAlert flat/>) ||
      ((inDemandPending || orgsPending || !org) && <LoadingPlaceholder flat/>) || (
        <>
          <CardSection
              ref={drop as Ref<Component<BoxProps>>}
              compact
          >
            <Box className={modified ? modifiedBorder : undefined}>
              <CardSection ref={selectedDrop as Ref<Component<BoxProps>>} shady>
                <BoxTypography variant="body2" color="text.secondary" className={sectionHeader}>
                  <FormattedMessage id="hr.in_demand_skills.common"/>
                </BoxTypography>
                <TeamSelectedSkills
                    variant="uniform"
                    skillIds={skillIds}
                    skills={skills as Record<number, Skill>}
                    onAddSkill={handleAddOpen}
                    onDelete={handleDeleteSkill}
                    onReset={modified ? resetItems : undefined}
                    disabled={disabled}
                    isActive={isActive}
                    emptyMessage="hr.in_demand_skills.empty"
                    warning={everybodyWarningValues ? 'hr.in_demand_skills.too_many' : undefined}
                    values={everybodyWarningValues}
                />
              </CardSection>
              <CardSection ref={employeeDrop as Ref<Component<BoxProps>>} shady>
                <BoxTypography variant="body2" color="text.secondary" className={sectionHeader}>
                  <FormattedMessage id="hr.in_demand_skills.employees_only"/>
                </BoxTypography>
                <TeamSelectedSkills
                    variant="extra"
                    skillIds={employeeSkillIds}
                    skills={skills as Record<number, Skill>}
                    onDelete={handleDeleteSkill}
                    disabled={disabled}
                    isActive={isEmployeeActive}
                    emptyMessage="hr.in_demand_skills.empty.employees_only"
                    warning={employeeWarningValues ? 'hr.in_demand_skills.too_many' : undefined}
                    values={employeeWarningValues}
                />
              </CardSection>
              <CardSection ref={managerDrop as Ref<Component<BoxProps>>} shady>
                <BoxTypography variant="body2" color="text.secondary" className={sectionHeader}>
                  <FormattedMessage id="hr.in_demand_skills.managers_only"/>
                </BoxTypography>
                <TeamSelectedSkills
                    variant="extra"
                    skillIds={managerSkillIds}
                    skills={skills as Record<number, Skill>}
                    onDelete={handleDeleteSkill}
                    disabled={disabled}
                    isActive={isManagerActive}
                    emptyMessage="hr.in_demand_skills.empty.managers_only"
                    warning={managerWarningValues ? 'hr.in_demand_skills.too_many' : undefined}
                    values={managerWarningValues}
                />
              </CardSection>
            </Box>
          </CardSection>
          {(suggestedFailed || (!suggestedPending && suggested && size(suggested) < 1)) && size(availableIds) < 1 ? undefined
          : (suggestedPending && (
            <Box pt={4.5} pb={1.5} display="flex" justifyContent="center" color="action.disabled">
              <CircularProgress color="inherit" size="2.25rem"/>
            </Box>
          )) || (
            <CardSection compact>
              <Box pt={2.5} pb={0.75} display="flex" alignItems="center" justifyContent="space-between">
                <BoxTypography color="info.caption" variant="subtitle2" fontStyle="italic">
                  <FormattedMessage id="hr.in_demand_skills.suggestions"/>
                </BoxTypography>
                <BoxTypography pl={2} color="text.secondary" variant="caption" fontStyle="italic">
                  <FormattedMessage id="hr.in_demand_skills.suggestions.info"/>
                </BoxTypography>
              </Box>
              <TeamSkills
                  variant="master"
                  skillIds={availableIds}
                  skills={skills as Record<number, Skill>}
                  disabled={disabled}
              />
            </CardSection>
          )}
        </>
      )}
      <CardFooter>
        <Button
            color="primary"
            variant="contained"
            size="large"
            disableElevation
            disabled={!org || disabled || !modified ||
              (size(skillIds) + size(employeeSkillIds) + size(managerSkillIds)) < 1 ||
              Boolean(everybodyWarningValues || employeeWarningValues || managerWarningValues)}
            startIcon={updatePending ? <CircularProgress size={18} color="inherit"/> : undefined}
            onClick={handleUpdate}
        >
          <FormattedMessage id="hr.in_demand_skills.button.submit"/>
        </Button>
      </CardFooter>
      {org && confirmMounted ? (
        <ConfirmDialog
            open={confirmOpen}
            text="hr.in_demand_skills.update_question"
            maxWidth="md"
            withCancelButton
            withExtraPadding
            withoutCloseButton
            values={msgValues}
            onCancel={handleCancel}
            onConfirm={handleConfirm}
            onExited={handleExited}
        />
      ) : undefined}
      <AddSkillPopover
          supervisor
          exclude={allSelectedIds}
          anchorEl={anchorAddBtn}
          onAdd={handleAddSkill}
          onCancel={handleAddClose}
          disabled={disabled}
          SkillSearchComponent={SkillSearch}
          SkillSearchComponentProps={skillSearchProps}
      />
      <ActionFailedAlert
          message="hr.in_demand_skills.submit.error"
          open={updateFailed}
      />
      <ActionSucceededMessage
          message="hr.in_demand_skills.submit.success"
          open={updateSucceeded}
          values={msgValues}
      />
    </>
  );
};

InDemandSkillsEditor.propTypes = InDemandSkillsEditorPropTypes;

export default memo(InDemandSkillsEditor);
