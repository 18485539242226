import { forwardRef, memo, useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import round from 'lodash/round';
import toFinite from 'lodash/toFinite';
import { useIntl } from 'react-intl';
import type EChartsReactCore from 'echarts-for-react/lib/core';
// Material UI imports
import { useTheme } from '@mui/material/styles';
// Skillmore UI Components
import { percentageOptions } from '@empathco/ui-components/src/common/intl';
import { spacing } from '@empathco/ui-components/src/helpers/styles';
import useCombinedRefs from '@empathco/ui-components/src/hooks/useCombinedRefs';
// local imports
import { GlobalEChartsStyles } from '../config/params';
import { svgPath as Bullseye } from '../icons/Bullseye';
import Chart from '../elements/Chart';
// SCSS imports
import { chart } from './EmployeeProgressChart.module.scss';

type EmployeeProgressChartProps = {
  initial?: number | null;
  current?: number | null;
};

const EmployeeProgressChartPropTypes = {
  initial: PropTypes.number,
  current: PropTypes.number
};

const EmployeeProgressChart = forwardRef<EChartsReactCore, EmployeeProgressChartProps>(({
  initial,
  current
}, ref) => {
  const theme = useTheme();
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage, formatNumber } = useIntl();

  const innerRef = useRef<EChartsReactCore>(null);
  const chartRef = useCombinedRefs<EChartsReactCore>(ref, innerRef);

  const initialValue = round(toFinite(initial));
  const currentValue = round(toFinite(current));
  const hasProgress = !isNil(current) && currentValue !== initialValue;
  const minValue = hasProgress && initialValue > currentValue ? currentValue : initialValue;

  const categories = useMemo(() => [
    ...hasProgress ? [formatMessage({ id: 'hr.dev_plan.chart.initial' })] : [],
    formatMessage({ id: 'hr.dev_plan.chart.current' }),
    formatMessage({ id: 'hr.dev_plan.chart.target' })
  ], [hasProgress, formatMessage]);

  const xAxisLabelFormatter = useCallback((value?: number | null) => categories[value || 0], [categories]);

  const yAxisLabelFormatter = useCallback(
    (value?: number | null) => value === initialValue || (value === currentValue && currentValue > 0) || value === 100
      ? formatNumber((value || 0) / 100, percentageOptions) : '',
    [initialValue, currentValue, formatNumber]
  );

  useEffect(() => {
    if (!innerRef.current) return;

    const echartInstance = innerRef.current.getEchartsInstance();

    echartInstance.setOption({
      ...GlobalEChartsStyles,
      grid: {
        show: true,
        top: 20,
        bottom: 60,
        left: 70,
        right: 45,
        borderColor: 'transparent',
        backgroundColor: theme.palette.background.chipRight
      },
      xAxis: {
        show: true,
        type: 'value',
        min: 0,
        max: hasProgress ? 2 : 1,
        interval: 1,
        minInterval: 1,
        maxInterval: 1,
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: {
          interval: 0,
          margin: spacing(3),
          fontSize: 15,
          fontWeight: theme.typography.fontWeightRegular,
          color: theme.palette.text.primary,
          formatter: xAxisLabelFormatter
        },
        splitLine: {
          show: true,
          interval: 0,
          lineStyle: {
            width: theme.shape.thinBorderWidth,
            color: theme.palette.divider
          }
        }
      },
      yAxis: {
        show: true,
        type: 'value',
        min: minValue,
        max: 100,
        interval: hasProgress ? Math.abs(currentValue - initialValue) : 100 - initialValue,
        axisTick: false,
        axisLine: { show: false },
        axisLabel: {
          showMinLabel: true,
          showMaxLabel: true,
          interval: 0,
          margin: spacing(3),
          fontSize: 15,
          fontWeight: theme.typography.fontWeightRegular,
          color: theme.palette.text.primary,
          formatter: yAxisLabelFormatter
        },
        splitLine: { show: false }
      },
      series: [
        ...hasProgress ? [
          {
            silent: true,
            type: 'line',
            data: [[0, initialValue], [1, current]],
            itemStyle: {
              color: theme.palette.primary.main
            },
            markPoint: {
              symbolSize: 14,
              symbolKeepAspect: true,
              data: [
                {
                  coord: [0, initialValue],
                  symbol: 'circle'
                }
              ]
            }
          }
        ] : [],
        {
          silent: true,
          type: 'line',
          data: hasProgress
            ? [[1, current], [2, 100]]
            : [[0, initialValue], [1, 100]],
          itemStyle: {
            color: theme.palette.primary.main
          },
          lineStyle: {
            type: [spacing(0.75), spacing(0.25)],
            dashOffset: spacing(0.325)
          },
          markPoint: {
            symbolSize: 14,
            symbolKeepAspect: true,
            data: [
              {
                coord: hasProgress ? [1, current] : [0, initialValue],
                symbol: 'circle'
              },
              {
                coord: [hasProgress ? 2 : 1, 100],
                symbolSize: 28,
                symbol: Bullseye
              }
            ]
          }
        }
      ]
    }, true);
    echartInstance.resize();
  }, [current, currentValue, hasProgress, initialValue, minValue, theme, xAxisLabelFormatter, yAxisLabelFormatter]);

  return <Chart ref={chartRef} option={GlobalEChartsStyles} className={chart}/>;
});

EmployeeProgressChart.displayName = 'EmployeeProgressChart';

EmployeeProgressChart.propTypes = EmployeeProgressChartPropTypes;

export default memo(EmployeeProgressChart);
