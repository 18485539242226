import { forwardRef, memo, useContext, useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import isNil from 'lodash/isNil';
import isSafeInteger from 'lodash/isSafeInteger';
import toSafeInteger from 'lodash/toSafeInteger';
import { useIntl, FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
// Skillmore UI Components
import type { ExportFormat } from '@empathco/ui-components/src/models/exportFormat';
import type { GetComponentProps } from '@empathco/ui-components/src/helpers/types';
import { paramsDiffer } from '@empathco/ui-components/src/helpers/pagination';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
// local imports
import { Job } from '../models/job';
import useCustomerSettings from '../config/customer';
import useExportJobCandidates from '../hooks/useExportJobCandidates';
import { JobCandidatesParams, SupervisorContext } from '../context/supervisor';
import { GlobalContext } from '../context/global';
import { DataContext } from '../context';
import useExport from '../hooks/useExport';
import EmployeeCard from '../v3/EmployeeCard';
import CardsGrid from '../v3/CardsGrid';
import PaginationControls from '../v3/PaginationControls';
import JobsIndexFilters from '../v3/JobsIndexFilters';

export type MatchingJobFilters = Omit<JobCandidatesParams, 'role_id' | 'export_mode' | 'offset' | 'limit'>;

const componentProps: Partial<GetComponentProps<typeof EmployeeCard>> = { matchRateVariant: 'default' };

type EmployeesMatchingJobListProps = {
  role: Job;
  isInternational?: boolean | null;
  testExportFailed?: boolean | null;
}

const EmployeesMatchingJobListPropTypes = {
  role: PropTypes.object.isRequired as Validator<Job>,
  isInternational: PropTypes.bool,
  testExportFailed: PropTypes.bool
};

// eslint-disable-next-line complexity
const EmployeesMatchingJobList = forwardRef<HTMLDivElement, EmployeesMatchingJobListProps>(({
  role,
  isInternational = false,
  testExportFailed = null
}, ref) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();
  const { HAS_INDEMAND_SKILLS } = useCustomerSettings();
  const { downloadAndSaveJobCandidates, JOB_CANDIDATES_CSV_COLUMNS } = useExportJobCandidates();
  const { user: { data: user } } = useContext(GlobalContext);
  const isAdminOnly = Boolean(user?.is_admin_only);
  const settingsId = isAdminOnly ? undefined : 'hr_candidates' as const;
  const {
    jobCandidates: { data, count, pending, failed, params }, requireJobCandidates,
    jobCandidatesCounts: { data: counts, params: countsParams }
  } = useContext(SupervisorContext);
  const {
    settings: { data: settingsData, pending: pendingSettings, failed: failedSettings },
    settingsUpdate: { pending: pendingSettingsUpdate }
  } = useContext(DataContext);
  const settingsLoaded = !settingsId || (pendingSettings === false && failedSettings === false && Boolean(settingsData));

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>();
  const [filters, setFilters] = useState<MatchingJobFilters>();

  const [matchedCount, optedOut] = useMemo(
    () => counts && countsParams?.role_id === role?.code ? [
      toSafeInteger(counts.total),
      toSafeInteger(counts.opted_out)
    ] : [0, 0],
    [counts, countsParams?.role_id, role?.code]
  );

  useEffect(() => {
    if (role?.code && filters && !isNil(pageSize) && requireJobCandidates) {
      const newParams: JobCandidatesParams = {
        role_id: role.code,
        country_id: filters.country_id,
        state_id: filters.state_id,
        min_match_rate: filters.min_match_rate,
        org_id: filters.org_id,
        limit: pageSize
      };
      let curPage = currentPage;
      if (paramsDiffer(params, newParams)) {
        curPage = 1;
        setCurrentPage(1);
      }
      requireJobCandidates({
        ...newParams,
        offset: pageSize * (curPage - 1)
       });
     }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role?.code, currentPage, pageSize, filters, requireJobCandidates]); // ignoring `params` changes

  const getExport = useCallback((format: ExportFormat, token: string) => {
    if (role && !isNil(pageSize)) {
      const { code, title } = role;
      return downloadAndSaveJobCandidates(
        format,
        token,
        code as string,
        title,
        formatMessage({ id: 'job_candidates.export.title' }, { role: title }),
        formatMessage({ id: 'supervisor.export.employees.footer' }, { count }),
        map(JOB_CANDIDATES_CSV_COLUMNS, (id) => formatMessage({ id })),
        !isNil(count) && count >= 1 && count <= pageSize ? data : undefined
      );
    }
    return null;
  }, [role, formatMessage, count, pageSize, data, JOB_CANDIDATES_CSV_COLUMNS, downloadAndSaveJobCandidates]);

  const exp = useExport(getExport, undefined, testExportFailed);

  const pendingAll = !settingsLoaded || !filters || pending;
  const loading = pendingAll || !data;
  const failedAny = failed || (settingsId ? failedSettings : false);
  const disabled = loading || failedAny;

  const total = !failedAny && (matchedCount >= 1 || optedOut >= 1) ? (
    <Box>
      <Box>
        <FormattedMessage
            id="job_candidates.results"
            values={{
              total: matchedCount,
              displaying: !pending && isSafeInteger(count) ? count || 0 : matchedCount - optedOut
            }}
        />
      </Box>
      {optedOut > 0 && (
        <BoxTypography variant="caption" fontStyle="italic" color="text.secondary">
          <FormattedMessage id="job_candidates.opted_out" values={{ count: optedOut }}/>
        </BoxTypography>
      )}
    </Box>
  ) : undefined;

  return (
    <>
      <CardsGrid
          ref={ref}
          items={data}
          variant="shady"
          pending={pendingAll}
          failed={failedAny}
          withoutTopPadding
          component={EmployeeCard}
          ComponentProps={componentProps}
          filters={(
            <JobsIndexFilters
                settingsId={settingsId}
                visible={settingsLoaded}
                onChange={setFilters}
                onExport={exp.handleExport}
                exportPending={exp.failed === false}
                exportDisabled={disabled || exp.failed === false || !count || count <= 0 || !role || !exp.enabled ||
                  isNil(pageSize)}
                disabled={pendingAll}
                isInternational={isInternational}
                withMatchRate
                withOrg={HAS_INDEMAND_SKILLS}
                withCountry
                withState
            />
          )}
          pagination={(
            <PaginationControls
                settingsId={settingsId}
                loaded={Boolean(data)}
                pending={pending}
                loading={pending}
                total={count}
                totalOverride={total}
                currentPage={currentPage}
                onPageSelected={setCurrentPage}
                onPageSize={setPageSize}
                disabled={disabled || pendingSettingsUpdate}
            />
          )}
      />
      <ActionFailedAlert
          message="job_candidates.export_failed"
          open={exp.failed}
          onDismiss={exp.handleDismiss}
      />
    </>
  );
});

EmployeesMatchingJobList.displayName = 'EmployeesMatchingJobList';

EmployeesMatchingJobList.propTypes = EmployeesMatchingJobListPropTypes;

export default memo(EmployeesMatchingJobList);
