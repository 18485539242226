import { createContext, useReducer, useEffect, useCallback, type ReactNode, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import { type AxiosResponse } from 'axios';
import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import FontFaceObserver from 'fontfaceobserver';
// Skillmore UI Components
import { isValidEmail } from '@empathco/ui-components/src/helpers/strings';
// local imports
import { AdminUser } from '../graphql/types';
import { APIActionResponse } from '../models/apiResponse';
import {
  SET_ACTIONS,
  AUTH, AUTHENTICATING, AUTHENTICATED, REAUTH, REAUTHENTICATING, REAUTHENTICATED,
  UNAUTHENTICATED, APP_ONLINE, CommonActions
} from '../constants/actionTypes';
import { isValidBuilderStep, BuilderStep } from '../constants/builder';
import { AuthErrorCode, AuthErrorCodeProp } from '../constants/authErrorCodes';
import useApiUrls, { axiosInstance as axios, API_USER_ME, API_EMAIL_AUTH_LINK } from '../config/api';
import { PATH_SUPERVISOR_EMPLOYEE } from '../config/paths';
import { ActionEntityProp, ActionState, ContextObject, ContextObjectProp } from '../models/contextEntity';
import { isManager, User } from '../models/user';
import useModels from '../helpers/models';
import { GlobalEChartsStyles } from '../config/params';
import { fetchFactory, APIResults } from '../helpers/actions';
import {
  getInitialActionState, getActionPendingState, getActionFinishedState,
  getInitialObjectState, getPendingObjectState, getFetchedObjectState, optimizeParams,
} from '../helpers/context';
import { updateEntity } from '../helpers/reducers';
import { removeAuthToken, setAuthToken, isLoggedIn, getAuthToken } from '../helpers/storage';
import useRedirects from '../helpers/redirect';
import { useMixpanel, currentEmployeeDetails } from './analytics';

export type GlobalEntityId = 'user';

export type Online = boolean | 'yes';
export type Token = string | boolean | null;

export type UserPaths = {
  supvEmplPath?: string;
}

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;
export function setApolloClient(client: ApolloClient<NormalizedCacheObject>) {
  apolloClient = client;
}

const getUserPaths = (user?: User | null): UserPaths => user ? {
  ...isManager(user) ? {
    supvEmplPath: PATH_SUPERVISOR_EMPLOYEE
  } : {}
} : {};

export interface AuthEmailParams {
  email: string;
  onSuccess?: () => void;
}

export interface AuthenticateParams {
  token?: string | null;
}

export interface IGlobalState {
  online: Online;
  fontsLoaded: boolean;
  token: Token;
  authErrorCode?: AuthErrorCode | null;
  user: ContextObject<User, {}>;
  paths: UserPaths;
  authEmail: ActionState<AuthEmailParams>;
  authenticate?: (params: AuthenticateParams) => void;
  unauthenticate?: () => void;
  sendAuthEmail?: (params: AuthEmailParams) => void;
  updateOnboardingSteps?: (step: string) => void;
}

export const GlobalStatePropTypes = PropTypes.shape({
  online: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.oneOf(['yes'])
  ]).isRequired,
  fontsLoaded: PropTypes.bool.isRequired,
  token: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  authErrorCode: AuthErrorCodeProp,
  user: ContextObjectProp,
  paths: PropTypes.object.isRequired,
  authEmail: ActionEntityProp,
  authenticate: PropTypes.func,
  unauthenticate: PropTypes.func,
  sendAuthEmail: PropTypes.func,
  updateOnboardingSteps: PropTypes.func
}) as Validator<IGlobalState>;

interface IUserApiResponse {
  token: string;
  impersonation?: boolean;
  employee?: User;
  user?: AdminUser | null;
}

const authResult = ((data: Partial<IUserApiResponse>): IUserApiResponse | null => data && (data.employee || data.user) ? {
  token: data.token || getAuthToken(),
  employee: (data?.employee ? {
    ...data.employee,
    is_superuser: data.employee.is_superuser || data.employee.user?.is_superuser || data.user?.is_superuser,
    is_admin_only: false
  } as User : undefined) || (data?.user ? {
    ...data.user,
    code: data.user.username,
    is_admin_only: true
  } as User : undefined)
} : null) as APIResults<IUserApiResponse, IUserApiResponse>;

// fonts loading status update
const FONTS_LOADED = 'FONTS_LOADED' as const;
// cached User details update
const ONBOARDING_STEPS_UPDATE = 'ONBOARDING_STEPS_UPDATE' as const;
// Send Auth Email
const AUTH_EMAIL_SENDING = 'AUTH_EMAIL_SENDING' as const;
const AUTH_EMAIL_SENT = 'AUTH_EMAIL_SENT' as const;

export type GlobalActions =
  | { type: typeof AUTHENTICATING; payload?: null; params: AuthenticateParams; }
  | { type: typeof AUTHENTICATED; payload: Partial<IUserApiResponse> | null; params: AuthenticateParams;
      error_code?: AuthErrorCode; }
  | { type: typeof REAUTHENTICATING; payload?: null; params?: null; }
  | { type: typeof REAUTHENTICATED; payload: Partial<IUserApiResponse> | null; params?: null; }
  | { type: typeof AUTH_EMAIL_SENDING; payload?: boolean | null; params: AuthEmailParams; }
  | { type: typeof AUTH_EMAIL_SENT; payload: boolean; params: AuthEmailParams; }
  | { type: typeof FONTS_LOADED; payload?: null; params?: null; }
  | { type: typeof ONBOARDING_STEPS_UPDATE; payload: Record<BuilderStep, boolean>; params?: null; }
  | CommonActions;

// eslint-disable-next-line complexity
const globalReducer = (state: IGlobalState, action: GlobalActions): IGlobalState => {
  switch (action.type) {
    case SET_ACTIONS: return { ...state, ...action.payload };

    case APP_ONLINE: return { ...state, online: action.payload };

    case FONTS_LOADED: return state.fontsLoaded ? state : { ...state, fontsLoaded: true };

    case AUTHENTICATING:
    case REAUTHENTICATING:
      return {
        ...state,
        token: null,
        user: getPendingObjectState(action.params || {}),
        paths: {}
      };

    case AUTHENTICATED:
    case REAUTHENTICATED:
      return {
        ...state,
        token: action.payload?.token || null,
        authErrorCode: (action.type === AUTHENTICATED && action.error_code) || null,
        user: action.type === REAUTHENTICATED && !action.payload?.employee
          ? getInitialObjectState()
          : getFetchedObjectState(action.payload?.employee || null, action.params || {}),
        paths: getUserPaths(action.payload?.employee)
      };

    case AUTH_EMAIL_SENDING: return { ...state, authEmail: getActionPendingState(action.params) };
    case AUTH_EMAIL_SENT: return { ...state, authEmail: getActionFinishedState(action.payload, action.params) };

    case ONBOARDING_STEPS_UPDATE:
      return {
        ...state,
        ...action.payload ? updateEntity(state, 'user' as GlobalEntityId,
          (user: User | null) => user ? {
            ...user, onboarding_steps: { ...user.onboarding_steps || {}, ...action.payload }
          } as User : user) : {}
      };

    case UNAUTHENTICATED: return {
      ...state,
      token: null,
      user: getInitialObjectState(),
      paths: {}
    };

    default: return state;
  }
};

const initialGlobalState: IGlobalState = {
  online: true,
  fontsLoaded: false,
  token: null,
  authErrorCode: null,
  user: {
    data: null,
    pending: true, // we are waiting for `useEffect` hook that checks `isLoggedIn()`
    failed: null,
    params: null
  },
  paths: {},
  authEmail: getInitialActionState()
};

export const GlobalContext = createContext<IGlobalState>(initialGlobalState);

type GlobalProviderProps = {
  children?: ReactNode | ReactNode[];
  // for Storybook only
  state?: IGlobalState;
  // for Jest specs only
  initState?: IGlobalState;
};

const GlobalProviderPropTypes = {
  // React built-in
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired as Validator<ReactNode | ReactNode[]>,
  // for Storybook only
  state: GlobalStatePropTypes,
  // for Jest specs only
  initState: GlobalStatePropTypes
};

export const GlobalProvider: FunctionComponent<GlobalProviderProps> = ({
  state,
  initState,
  children
}) => {
  const { API_USER_TOKEN_BY_SSO } = useApiUrls();
  const { getRedirectionPath } = useRedirects();
  const { getLocationStr } = useModels();

  const [globalState, dispatch] = useReducer(globalReducer, initState
    ? { ...initialGlobalState, ...initState } : initialGlobalState
  );
  const navigate = useNavigate();
  const [, , removeCookie] = useCookies<string>([]);
  const mixpanel = useMixpanel();

  const actualState = state || globalState;
  const { fontsLoaded, online, token, user: { pending }, authEmail: { pending: authEmailPending } } = actualState;

  function unauthenticate() {
    removeAuthToken();
    currentEmployeeDetails.code = undefined;
    currentEmployeeDetails.distinct_id = undefined;
    dispatch({ type: UNAUTHENTICATED });
    apolloClient?.resetStore();
  }

  function updateOnboardingSteps(step: string) {
    if (isValidBuilderStep(step)) dispatch({
      type: ONBOARDING_STEPS_UPDATE,
      payload: { [step]: true } as Record<BuilderStep, boolean>
    });
  }

  function networkChange() {
    dispatch({
      type: APP_ONLINE,
      payload: Boolean(window.navigator.onLine)
    });
  }

  function fontsLoadCompleted() {
    dispatch({ type: FONTS_LOADED });
  }

  const onSuccess = useCallback((employee?: User, noDefaultPath = false): void => {
    removeCookie('sessionid');
    removeCookie('saml_session');
    if (employee) {
      const { code, location } = employee;
      if (location) location.title = getLocationStr(location) || location.title;
      currentEmployeeDetails.code = code;
      currentEmployeeDetails.distinct_id = code;
      mixpanel.identify(code);
      const path = getRedirectionPath(employee, noDefaultPath);
      if (path) navigate(path, { replace: true });
    } else {
      currentEmployeeDetails.code = undefined;
      currentEmployeeDetails.distinct_id = undefined;
    }
  }, [navigate, removeCookie, mixpanel, getRedirectionPath, getLocationStr]);

  const onError = useCallback((): void => {
    removeAuthToken();
    removeCookie('sessionid');
    removeCookie('saml_session');
    currentEmployeeDetails.code = undefined;
    currentEmployeeDetails.distinct_id = undefined;
  }, [removeCookie]);

  useEffect(() => {
    window.addEventListener('offline', networkChange);
    window.addEventListener('online', networkChange);
    // Font Loading
    if (!fontsLoaded) {
      const testString = 'BESÅãbswy' as const;
      const { fontFamily } = GlobalEChartsStyles.textStyle;
      Promise.all([
        // IMPORTANT! List of weights and styles must be in sync with imports in:
        // `@empathco/ui-components/src/styles/roboto.scss`
        new FontFaceObserver(fontFamily, { weight: 400 }).load(testString),
        new FontFaceObserver(fontFamily, { weight: 500 }).load(testString),
        new FontFaceObserver(fontFamily, { weight: 700 }).load(testString),
        new FontFaceObserver(fontFamily, { weight: 300 }).load(testString),
        new FontFaceObserver(fontFamily, { weight: 400, style: 'italic' }).load(testString),
        new FontFaceObserver(fontFamily, { weight: 500, style: 'italic' }).load(testString)
      ])
        .then(fontsLoadCompleted)
        .catch(fontsLoadCompleted);
    }
    dispatch({
      type: SET_ACTIONS,
      payload: {
        updateOnboardingSteps,
        unauthenticate
      }
    });
    return () => {
      window.removeEventListener('online', networkChange);
      window.removeEventListener('offline', networkChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // we do not need to monitor `fontsLoaded` here

  useEffect(() => {
    if (!token) {
      if (isLoggedIn()) {
        fetchFactory({
          token: getAuthToken(),
          online: true,
          dispatch,
          type: REAUTH,
          entity: getInitialObjectState<User, {}>(),
          api: API_USER_ME,
          results: authResult,
          onSuccess: (data) => {
            apolloClient?.resetStore();
            onSuccess(data.employee, true);
          },
          onError
        })();
      } else {
        dispatch({ type: UNAUTHENTICATED });
      }
    }
  }, [token, onSuccess, onError]);

  useEffect(() => {
    dispatch({
      type: SET_ACTIONS,
      payload: {
        authenticate: fetchFactory({
          token: true,
          online,
          dispatch,
          params: ['token'],
          type: AUTH,
          entity: { data: null, pending, failed: null, params: null } as ContextObject<User>,
          api: API_USER_TOKEN_BY_SSO,
          method: 'POST',
          results: authResult,
          onSuccess: (data) => {
            apolloClient?.resetStore();
            setAuthToken(data.token);
            onSuccess(data.employee, false);
          },
          onError
        })
      }
    });
  }, [online, pending, onSuccess, onError, API_USER_TOKEN_BY_SSO]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      sendAuthEmail: async ({
        email,
        onSuccess: onEmailSent
      }: AuthEmailParams = {} as AuthEmailParams) => {
        if (authEmailPending || !isValidEmail(email)) return;
        const params = optimizeParams({ email }, online);
        try {
          if (!online) throw new Error();
          dispatch({
            type: AUTH_EMAIL_SENDING,
            params
          });
          const { status, data } = await axios.request<AuthEmailParams, AxiosResponse<APIActionResponse>>({
            method: 'POST',
            url: API_EMAIL_AUTH_LINK,
            data: params
          }) || {};
          if (status < 200 || status > 201 || !data || !data.success) throw new Error();
          onEmailSent?.();
          dispatch({
            type: AUTH_EMAIL_SENT,
            payload: true,
            params
          });
        } catch (error) {
          dispatch({
            type: AUTH_EMAIL_SENT,
            payload: false,
            params
          });
        }
      }
    }});
  }, [authEmailPending, online]);

  return (
    <GlobalContext.Provider value={actualState}>
      {children}
    </GlobalContext.Provider>
  );
};

GlobalProvider.propTypes = GlobalProviderPropTypes;
