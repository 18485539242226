import { memo, useEffect, useMemo, useRef, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import sum from 'lodash/sum';
import head from 'lodash/head';
import clamp from 'lodash/clamp';
import isNil from 'lodash/isNil';
import replace from 'lodash/replace';
import toSafeInteger from 'lodash/toSafeInteger';
import type EChartsReactCore from 'echarts-for-react/lib/core';
import { LinearGradient } from 'echarts/lib/export/api/graphic';
import { useIntl } from 'react-intl';
// Material UI imports
import { useTheme } from '@mui/material/styles';
// Skillmore UI Components
import { spacing } from '@empathco/ui-components/src/helpers/styles';
// local imports
import { SkillEmployees } from '../models/skillEmployees';
import { SkillLevel, SKILL_LEVEL_FIRST, SKILL_LEVEL_MAX } from '../models/skill';
import { GlobalEChartsStyles } from '../config/params';
import Chart from '../elements/Chart';
// SCSS imports
import { chart } from './EmployeesWithSkillChart.module.scss';

type EmployeesWithSkillChartProps = {
  level: SkillLevel;
  setLevel: (level: SkillLevel) => void;
  employeeCounts: SkillEmployees;
}

const EmployeesWithSkillChartPropTypes = {
  level: PropTypes.number.isRequired as Validator<SkillLevel>,
  setLevel: PropTypes.func.isRequired,
  employeeCounts: PropTypes.object.isRequired as Validator<SkillEmployees>
};

const EmployeesWithSkillChart: FunctionComponent<EmployeesWithSkillChartProps> = ({
  level,
  setLevel,
  employeeCounts
}) => {
  const theme = useTheme();
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage, formatNumber } = useIntl();

  const chartRef = useRef<EChartsReactCore>(null);

  const onEvents = useMemo(() => ({
    selectchanged: ({ selected }: {
      selected: {
        dataIndex: number[];
      }[];
    }) => {
      const dataIndex = head(head(selected)?.dataIndex);
      setLevel(isNil(dataIndex) ? 0 : clamp(toSafeInteger(dataIndex) + 1, SKILL_LEVEL_FIRST, SKILL_LEVEL_MAX) as SkillLevel);
    }
  }), [setLevel]);

  const { level1, level2, level3, level4, all_skill_employees_count, all_employees_count } = employeeCounts || {};
  const total = sum([level1, level2, level3, level4]);

  const totalEmployeesText = useMemo(() => formatMessage({ id: 'skill.total_employees' }), [formatMessage]);
  const employeesText = useMemo(() => formatMessage({ id: 'skill.employees_with_this_skill' }), [formatMessage]);

  useEffect(() => {
    if (!chartRef.current) return;

    const echartInstance = chartRef.current.getEchartsInstance();

    const styles = [
      // levels: 1-4
      { color: theme.palette.primary.lighter, borderColor: theme.palette.primary.lighter },
      { color: theme.palette.primary.light, borderColor: theme.palette.primary.light },
      { color: theme.palette.primary.dark, borderColor: theme.palette.primary.dark },
      { color: theme.palette.secondary.dark, borderColor: theme.palette.secondary.dark },
      // empty
      { color: theme.palette.background.paper, borderColor: theme.palette.background.paper }
    ];

    echartInstance.setOption({
      ...GlobalEChartsStyles,
      title: [
        {
          text: formatNumber(all_employees_count),
          subtext: totalEmployeesText,
          textAlign: 'center',
          textVerticalAlign: 'middle',
          left: '24.5%',
          top: '33.5%',
          textStyle: {
            fontSize: 25,
            fontWeight: theme.typography.fontWeightBold,
            lineHeight: 20,
            color: theme.palette.text.label
          },
          subtextStyle: {
            fontSize: 17,
            lineHeight: 20,
            color: theme.palette.text.label
          }
        },
        {
          text: formatMessage(
            { id: 'skill.employees_with_skill_value' },
            { count: toSafeInteger(all_skill_employees_count) }
          ),
          textAlign: 'center',
          textVerticalAlign: 'middle',
          left: '24.5%',
          bottom: '5%',
          textStyle: {
            rich: {
              label: {
                fontSize: 18,
                color: theme.palette.text.label
              },
              bold: {
                fontSize: 18,
                fontWeight: theme.typography.fontWeightBold,
                color: theme.palette.text.label
              }
            }
          }
        },
        {
          text: formatNumber(total),
          subtext: employeesText,
          textAlign: 'center',
          textVerticalAlign: 'middle',
          left: '69.5%',
          top: '33.5%',
          textStyle: {
            fontSize: 25,
            fontWeight: theme.typography.fontWeightBold,
            lineHeight: 32,
            color: theme.palette.text.label
          },
          subtextStyle: {
            fontSize: 17,
            lineHeight: 22,
            color: theme.palette.text.label
          }
        },
        {
          text: level >= SKILL_LEVEL_FIRST ? formatMessage({ id: 'skill.employees_with_level' }, {
            count: toSafeInteger([level1, level2, level3, level4][level - 1]),
            level: replace(formatMessage({ id: 'common.skill_level.description' }, { level }), ' – ', '-')
          }) : '',
          textAlign: 'center',
          textVerticalAlign: 'middle',
          left: '69.5%',
          bottom: 0,
          textStyle: {
            rich: {
              label: {
                fontSize: 18,
                color: theme.palette.text.label
              },
              bold: {
                fontSize: 18,
                fontWeight: theme.typography.fontWeightBold,
                color: theme.palette.text.label
              }
            }
          }
        }
      ],
      series: [
        {
          silent: true,
          name: 'totals',
          type: 'pie',
          radius: ['50%', '55%'],
          center: ['25%', '42.5%'],
          avoidLabelOverlap: false,
          label: { show: false },
          labelLine: { show: false },
          data: [
            ...all_skill_employees_count ? [
              {
                value: toSafeInteger(all_skill_employees_count),
                name: 'with_skill',
                itemStyle: {
                  color: new LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: theme.palette.success.light },
                    { offset: 1, color: theme.palette.success.dark }
                  ])
                }
              }
            ] : [],
            {
              value: toSafeInteger(all_employees_count - all_skill_employees_count),
              name: 'without_skill',
              itemStyle: { color: theme.palette.primary.contrastText }
            }
          ]
        },
        {
          silent: total < 1,
          name: 'levels',
          type: 'pie',
          radius: ['47.5%', '70%'],
          center: ['70%', '42.5%'],
          selectedMode: 'single',
          selectedOffset: spacing(1.5),
          label: { show: false },
          labelLine: { show: false },
          emphasis: { scale: false },
          select: {
            itemStyle: {
              borderWidth: spacing(1.5),
              borderRadius: 0
            }
          },
          itemStyle: { borderRadius: spacing(0.25) },
          data: [
            level1 < 1 ? {} : {
              value: toSafeInteger(level1),
              name: 'level1',
              selected: level === 1 && level1 < total,
              itemStyle: styles[0],
              emphasis: { itemStyle: styles[0] }
            },
            level2 < 1 ? {} : {
              value: toSafeInteger(level2),
              name: 'level2',
              selected: level === 2 && level2 < total,
              itemStyle: styles[1],
              emphasis: { itemStyle: styles[1] }
            },
            level3 < 1 ? {} : {
              value: toSafeInteger(level3),
              name: 'level3',
              selected: level === 3 && level3 < total,
              itemStyle: styles[2],
              emphasis: { itemStyle: styles[2] }
            },
            level4 < 1 ? {} : {
              value: toSafeInteger(level4),
              name: 'level4',
              selected: level === 4 && level4 < total,
              itemStyle: styles[3],
              emphasis: { itemStyle: styles[3] }
            },
            ...total < 1 ? [
              {
                value: 1,
                name: 'empty',
                selected: false,
                itemStyle: styles[4],
                emphasis: { itemStyle: styles[4] }
              }
            ] : []
          ]
        }
      ]
    }, true);
    echartInstance.resize();
  }, [
    level, all_employees_count, all_skill_employees_count, level1, level2, level3, level4, total,
    employeesText, totalEmployeesText, formatNumber, formatMessage, theme
  ]);

  return (
    <Chart
        ref={chartRef}
        option={GlobalEChartsStyles}
        onEvents={onEvents}
        className={chart}
    />
  );
};

EmployeesWithSkillChart.propTypes = EmployeesWithSkillChartPropTypes;

export default memo(EmployeesWithSkillChart);
