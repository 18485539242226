import { memo, useContext, useMemo, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import toLower from 'lodash/toLower';
import { ArcherContainer, ArcherElement } from 'react-archer';
import { useIntl } from 'react-intl';
// Material UI imports
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
// Skillmore UI Components
import GridBox from '@empathco/ui-components/src/mixins/GridBox';
import FetchFailedAlert from '@empathco/ui-components/src/elements/FetchFailedAlert';
import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
// local imports
import { getDataStatus, isDirtyData } from '../constants/dataStatuses';
import { Job } from '../models/job';
import { MatchRateObject } from '../models/matchRateObject';
import { PATH_JOB } from '../config/paths';
import { DataContext } from '../context';
import ComputeUpdatesOverlay from '../v3/ComputeUpdatesOverlay';
import RoleSimpleCard, {
  RoleSmpleIdType, RoleSmpleType,
  ROLES_SIMPLE, ROLE_SIMPLE_CURRENT, ROLE_SIMPLE_PREV,
  ROLE_SIMPLE_CURRENT_ID, ROLE_SIMPLE_NEXT_ID
} from '../v3/RoleSimpleCard';
// SCSS imports
import { bg, headlines, arrows } from './MostCommonJobMoves.module.scss';

const jobElementId = (role: Job, groupId: RoleSmpleIdType) => `${ROLES_SIMPLE[groupId]}_${role.id}`;

type MostCommonJobMovesProps = {
  // uid?: string | null;
  jobId?: number | null;
  isContractorRole?: boolean | null;
  isEmployee?: boolean;
  onClick?: (code: string, job?: Job) => void;
}

const MostCommonJobMovesPropTypes = {
  // attributes
  // uid: PropTypes.string,
  jobId: PropTypes.number,
  isContractorRole: PropTypes.bool,
  isEmployee: PropTypes.bool,
  onClick: PropTypes.func
};

// eslint-disable-next-line complexity
const MostCommonJobMoves: FunctionComponent<MostCommonJobMovesProps> = ({
  // uid,
  jobId,
  isContractorRole = false,
  isEmployee = false,
  onClick
}) => {
  const theme = useTheme();
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();
  const {
    dataStatus: { data: dataStatus, pending: pendingStatus, failed: failedStatus },
    matchRate: { data: matchRate, pending: pendingMatch, failed: failedMatch, params: paramsMatch },
    jobMoves: { data: jobMoves, pending, failed }
  } = useContext(DataContext);
  const dirty = !isContractorRole && isEmployee && isDirtyData(getDataStatus(dataStatus));

  const titles = useMemo(() => [
    formatMessage({ id: 'role.previous_roles' }),
    formatMessage({ id: 'role.this_role' }),
    formatMessage({ id: 'role.next_roles' })
  ], [formatMessage]);

  const { prev_jobs, current_job, next_jobs } = jobMoves || {};
  const currentJobElementId = current_job?.id ? jobElementId(current_job, ROLE_SIMPLE_CURRENT_ID) : undefined;

  const jobs = [prev_jobs || [], current_job ? [current_job] : [], next_jobs || []];

  const loading = !jobId || pending || pendingStatus || !jobMoves || !current_job || !prev_jobs || !next_jobs ||
    !isArray(prev_jobs) || !isPlainObject(current_job) || !isArray(next_jobs);

  const { match_rate } = dirty && !pendingMatch && !failedMatch && matchRate && paramsMatch
    ? matchRate : {} as MatchRateObject;

  return ((failed || failedStatus) && <FetchFailedAlert flat/>) ||
    (loading && <LoadingPlaceholder flat/>) || (
      <Box flexGrow={1} display="flex" flexDirection="column" position="relative">
        <CardSection bottom className={bg}>
          <GridBox container justifyContent="space-between" pt={2}>
            {map(ROLES_SIMPLE, (__, index) => (
              <Grid key={index} item xs={3} container direction="column" justifyContent="center">
                <Box className={headlines}>
                  {titles[index]}
                </Box>
              </Grid>
            ))}
          </GridBox>
          <Box className={arrows}>
            <ArcherContainer strokeColor={`url(#jobMoveArrowGradient) ${theme.palette.primary.main}`}>
              <Grid container justifyContent="space-between">
                {map(ROLES_SIMPLE, (variant: RoleSmpleType, index: RoleSmpleIdType) => (
                  <Grid key={index} item xs={3} container direction="column" justifyContent="center">
                    {map(jobs[index], (role) => {
                      const isCurrent = variant === ROLE_SIMPLE_CURRENT;
                      return (
                        <ArcherElement
                            key={role.id}
                            id={jobElementId(role, index)}
                            relations={(isCurrent && map(next_jobs, (job) => ({
                              targetId: jobElementId(job, ROLE_SIMPLE_NEXT_ID),
                              targetAnchor: 'left',
                              sourceAnchor: 'right',
                              style: { strokeWidth: 2 }
                            }))) || (variant === ROLE_SIMPLE_PREV && currentJobElementId && [
                              {
                                targetId: currentJobElementId,
                                targetAnchor: 'left',
                                sourceAnchor: 'right',
                                style: { strokeWidth: 2 }
                              }
                            ]) || undefined}
                        >
                          <RoleSimpleCard
                              role={role}
                              variant={variant}
                              path={jobId !== role.id && !onClick ? PATH_JOB : undefined}
                              onClick={(jobId !== role.id && onClick) || undefined}
                              withoutMatchRate={!isEmployee}
                              matchRate={isEmployee && paramsMatch && toLower(paramsMatch.role_id) === toLower(role.code)
                                ? match_rate : undefined}
                          />
                        </ArcherElement>
                      );
                    })}
                  </Grid>
                ))}
              </Grid>
            </ArcherContainer>
          </Box>
        </CardSection>
        {dirty ? <ComputeUpdatesOverlay/> : undefined}
      </Box>
    );
};

MostCommonJobMoves.propTypes = MostCommonJobMovesPropTypes;

export default memo(MostCommonJobMoves);
