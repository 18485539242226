import { useCallback, useMemo, type ReactElement, type ReactNode, type ForwardedRef } from 'react';
// import PropTypes, { Validator } from 'prop-types';
import map from 'lodash/map';
import transform from 'lodash/transform';
import { useIntl } from 'react-intl';
// Material UI imports
import Select, { type SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
// Skillmore UI Components
import { simpleForwardRef } from '@empathco/ui-components/src/helpers/react';
import { DEFAULT_MENU_RIGHT } from '@empathco/ui-components/src/helpers/menus';
// SCSS imports
import { fixWidth } from './SortSelector.module.scss';

const SORT_SELECTOR_VARIANT = [
  'skills', 'best_matches', 'employees', 'jobs_order', 'job_skills_order', 'skill_dev', 'opportunities',
  'course_completeion_order'
] as const;
export type SortSelectorVariant = typeof SORT_SELECTOR_VARIANT[number];

type SortSelectorProps<T> = {
  readonly variant: SortSelectorVariant;
  readonly value: T;
  readonly onChange: (sortValue: T, newDir?: boolean | null) => void;
  readonly disabled?: boolean;
  readonly values: readonly T[];
  readonly defaultValue?: T;
  readonly className?: string;
};

// const SortSelectorPropTypes = {
//   variant: PropTypes.oneOf(SORT_SELECTOR_VARIANT).isRequired,
//   value: PropTypes.string.isRequired as Validator<T>,
//   onChange: PropTypes.func.isRequired,
//   disabled: PropTypes.bool,
//   values: PropTypes.array.isRequired as Validator<T[]>,
//   defaultValue: PropTypes.string as Validator<T>,
//   className: PropTypes.string
// };

function SortSelectorRender<T extends string>({
  variant,
  value,
  onChange,
  disabled = false,
  values,
  defaultValue,
  className
}: SortSelectorProps<T>, ref: ForwardedRef<HTMLDivElement>): ReactElement {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();

  const labels = useMemo(() => transform(values, (result, sort) => {
    result[sort] = formatMessage({ id: `sort.${variant}.value` }, { sort });
  }, { '': formatMessage({ id: `sort.${variant}.label` }) } as Record<string, string>), [variant, values, formatMessage]);

  const renderValue = useCallback(
    (sort: unknown): ReactNode => sort === defaultValue ? labels[''] : labels[sort as T],
    [defaultValue, labels]
  );

  const handleSortChange = useCallback((event: SelectChangeEvent<T>) => {
    if (onChange && event) onChange(event.target?.value as T);
  }, [onChange]);

  return (
    <FormControl ref={ref} variant="outlined" size="small" className={className}>
      <Select
          value={value}
          renderValue={renderValue}
          onChange={handleSortChange}
          disabled={disabled}
          MenuProps={DEFAULT_MENU_RIGHT}
          className={variant === 'employees' || variant === 'jobs_order' || variant === 'job_skills_order' ||
            variant === 'course_completeion_order' ? undefined : fixWidth}
      >
        {map(values, (sort) => (
          <MenuItem key={sort} value={sort}>
            {labels[sort]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

const SortSelector = simpleForwardRef(SortSelectorRender);

// SortSelector.displayName = 'SortSelector';

// SortSelector.propTypes = SortSelectorPropTypes;

export default SortSelector;
