import { memo, useContext, useEffect, useCallback, useMemo, useState, type FunctionComponent } from 'react';
import map from 'lodash/map';
import size from 'lodash/size';
import keys from 'lodash/keys';
import omit from 'lodash/omit';
import xor from 'lodash/xor';
import intersection from 'lodash/intersection';
import isNil from 'lodash/isNil';
import isBoolean from 'lodash/isBoolean';
import transform from 'lodash/transform';
import { useIntl } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
// Skillmore UI Components
import FetchFailedAlert from '@empathco/ui-components/src/elements/FetchFailedAlert';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
import ContentCard from '@empathco/ui-components/src/elements/ContentCard';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import CardSubheader from '@empathco/ui-components/src/elements/CardSubheader';
// local imports
import { isNotContractor } from '../models/user';
import { EmailNotificationType, EMAIL_NOTIFICATIONS, EMAIL_NOTIFICATIONS_REDUCED_UI } from '../constants/emailNotifications';
import { Skill, SKILL_LEVEL_TO_MENTOR } from '../models/skill';
import useCustomerSettings from '../config/customer';
import useModels from '../helpers/models';
import { GlobalContext } from '../context/global';
import { DataContext } from '../context';
import CheckedSkillsGrid from '../v3/CheckedSkillsGrid';
import PreferencesRow from '../v3/PreferencesRow';

type EmailNotificationsState = Record<EmailNotificationType, boolean>;

// eslint-disable-next-line complexity, max-statements
const Preferences: FunctionComponent = () => {
  const { HAS_MENTORING, HAS_OPPORTUNITIES } = useCustomerSettings();
  const { updateCachedPreferencesSkills } = useModels();

  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();
  const { user: { data: user } } = useContext(GlobalContext);
  const {
    preferences: { data: preferences, pending: preferencesPending, failed: preferencesFailed }, requirePreferences,
    preferencesUpdate: { pending: updatePending, failed: updateFailed }, updatePreferences
  } = useContext(DataContext);
  const preferencesLoaded = preferencesPending === false && preferencesFailed === false && Boolean(preferences);
  const nonReducedUI = isNotContractor(user);
  const EMAIL_NOTIFICATIONS_VALUES = nonReducedUI ? EMAIL_NOTIFICATIONS : EMAIL_NOTIFICATIONS_REDUCED_UI;

  const [isPublic, setIsPublic] = useState<boolean | null>(null);
  const [openToOpportunities, setOpenToOpportunities] = useState<boolean | null>(null);
  const [showTargetJob, setShowTargetJob] = useState<boolean | null>(null);
  const [emailNotifications, setEmailNotifications] = useState<EmailNotificationsState | null>(null);
  const [isMentor, setIsMentor] = useState<boolean | null>(null);
  const [skills, setSkills] = useState<Skill[] | null>(null);
  const [skillsToMentor, setSkillsToMentor] = useState<Skill[] | null>(null);
  const waiting = isNil(isPublic) || isNil(showTargetJob) || isNil(emailNotifications) || isNil(isMentor) || isNil(skills);

  useEffect(() => {
    requirePreferences?.();
  }, [requirePreferences]);

  // Preferences loaded or preferences update finished (succeeded or failed)
  useEffect(() => {
    if (preferencesLoaded && !updatePending && preferences) {
      setIsPublic(Boolean(preferences.skills_profile_is_public));
      setOpenToOpportunities(HAS_OPPORTUNITIES && Boolean(preferences.open_to_opportunities));
      setShowTargetJob(Boolean(preferences.my_manager_can_view_my_target_job));
      if (preferences.email_notifications) setEmailNotifications(
        transform(preferences.email_notifications, (result, id) => {
          result[id] = true;
        }, {} as EmailNotificationsState)
      );
      setIsMentor(HAS_MENTORING && Boolean(preferences.mentor_others));
      setSkills((HAS_MENTORING && preferences.skills_i_mentor) || []);
      setSkillsToMentor((HAS_MENTORING && preferences.skills_i_can_mentor) || []);
    }
  }, [preferencesLoaded, updatePending, preferences, HAS_MENTORING, HAS_OPPORTUNITIES]);

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (preferencesLoaded && updatePreferences && !waiting && preferences) {
      const email_notifications = intersection(keys(emailNotifications), EMAIL_NOTIFICATIONS_VALUES) as EmailNotificationType[];
      const skills_i_mentor = HAS_MENTORING && nonReducedUI ? map(skills, 'id') : [];
      const newPreferences = {
        ...nonReducedUI && isBoolean(isPublic) && isPublic !== preferences.skills_profile_is_public
          ? { skills_profile_is_public: isPublic } : {},
        ...HAS_OPPORTUNITIES && nonReducedUI && isBoolean(openToOpportunities) &&
          openToOpportunities !== preferences.open_to_opportunities ? { open_to_opportunities: openToOpportunities } : {},
        ...nonReducedUI && isBoolean(showTargetJob) && showTargetJob !== preferences.my_manager_can_view_my_target_job
          ? { my_manager_can_view_my_target_job: showTargetJob } : {},
        ...size(xor(email_notifications, preferences.email_notifications)) >= 1
          ? { email_notifications } : {},
        ...HAS_MENTORING && nonReducedUI && isBoolean(isMentor) && isMentor !== preferences.mentor_others
          ? { mentor_others: isMentor } : {},
        ...HAS_MENTORING && nonReducedUI && size(xor(skills_i_mentor, map(preferences.skills_i_mentor, 'id'))) >= 1
          ? { skills_i_mentor } : {}
      };
      if (size(newPreferences) >= 1) updatePreferences(newPreferences);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // ignoring `preferences` changes:
    isPublic, showTargetJob, emailNotifications, isMentor, skills, nonReducedUI, openToOpportunities,
    waiting, preferencesLoaded, updatePreferences, EMAIL_NOTIFICATIONS_VALUES, HAS_MENTORING, HAS_OPPORTUNITIES
  ]);

  const emailNotificationChoices = useMemo(() => map(EMAIL_NOTIFICATIONS_VALUES, (id) => ({
    id,
    text: formatMessage({ id: `preferences.email.${id}` })
  })), [formatMessage, EMAIL_NOTIFICATIONS_VALUES]);

  const handlePublicityChange = useCallback((value: boolean) => setIsPublic(Boolean(value)), []);
  const handleOpenToOpportunities = useCallback((value: boolean) => setOpenToOpportunities(Boolean(value)), []);
  const handleShowTargetJobChange = useCallback((value: boolean) => setShowTargetJob(Boolean(value)), []);

  const handleNotificationsChange = useCallback((key: string) => {
    setEmailNotifications((prev) => (prev && prev[key as EmailNotificationType]
      ? omit(prev, key)
      : { ...prev || {}, [key]: true }
    ) as EmailNotificationsState);
  }, []);

  const handleMentorshipChange = useCallback((value: boolean) => setIsMentor(Boolean(value)), []);

  const handleMentorshipOff = useCallback((skill_id: number) => {
    const pref = updateCachedPreferencesSkills(preferences, {
      skill_id,
      level: SKILL_LEVEL_TO_MENTOR,
      is_opt_in_mentor: false
    });
    if (pref) {
      setSkills(pref.skills_i_mentor || []);
      setSkillsToMentor(pref.skills_i_can_mentor || []);
    }
  }, [preferences, updateCachedPreferencesSkills]);

  const handleMentorshipOn = useCallback((skill_id: number) => {
    const pref = updateCachedPreferencesSkills(preferences, {
      skill_id,
      level: SKILL_LEVEL_TO_MENTOR,
      is_opt_in_mentor: true
    });
    if (pref) {
      setSkills(pref.skills_i_mentor || []);
      setSkillsToMentor(pref.skills_i_can_mentor || []);
    }
  }, [preferences, updateCachedPreferencesSkills]);

  const loading = preferencesPending || !preferences || waiting;
  const hasSkillsMentorship = HAS_MENTORING && size(skills) >= 1;
  const hasSkillsToMentor = HAS_MENTORING && size(skillsToMentor) >= 1;

  return (
    <ContentCard>
      <CardTitle
          title="preferences.title"
          withDivider
      />
      {(preferencesFailed && <FetchFailedAlert flat/>) ||
      (loading && <LoadingPlaceholder flat/>) || (
        <>
          <CardSection>
            {nonReducedUI ? (
              <PreferencesRow
                  prompt="preferences.public_profile"
                  value={Boolean(isPublic)}
                  onChange={handlePublicityChange}
                  disabled={updatePending || isNil(isPublic)}
              />
            ) : undefined}
            {nonReducedUI ? (
              <Box py={2.5}>
                <Divider/>
              </Box>
            ) : undefined}
            {nonReducedUI ? (
              <PreferencesRow
                  prompt="preferences.public_target_job"
                  value={Boolean(showTargetJob)}
                  onChange={handleShowTargetJobChange}
                  disabled={updatePending || isNil(showTargetJob)}
              />
            ) : undefined}
            {nonReducedUI ? (
              <Box py={2.5}>
                <Divider/>
              </Box>
            ) : undefined}
            <PreferencesRow
                prompt="preferences.notifications"
                values={emailNotifications}
                choices={emailNotificationChoices}
                onValuesChange={handleNotificationsChange}
                disabled={updatePending || isNil(emailNotifications)}
            />
            {HAS_OPPORTUNITIES && nonReducedUI ? (
              <Box py={2.5}>
                <Divider/>
              </Box>
          ) : undefined}
            {HAS_OPPORTUNITIES && nonReducedUI ? (
              <PreferencesRow
                  prompt="preferences.open_to_opportunities"
                  value={Boolean(openToOpportunities)}
                  onChange={handleOpenToOpportunities}
                  disabled={updatePending || isNil(openToOpportunities)}
              />
            ) : undefined}
            {HAS_MENTORING && nonReducedUI ? (
              <Box py={2.5}>
                <Divider/>
              </Box>
            ) : undefined}
            {HAS_MENTORING && nonReducedUI ? (
              <PreferencesRow
                  prompt="preferences.opt_in_mentor"
                  value={Boolean(isMentor)}
                  onChange={handleMentorshipChange}
                  disabled={updatePending || isNil(isMentor)}
              />
            ) : undefined}
          </CardSection>
          {HAS_MENTORING && nonReducedUI ? (
            <Collapse in={Boolean(isMentor)}>
              {hasSkillsMentorship ? (
                <>
                  <CardSection compact>
                    <CardSubheader
                        text={hasSkillsMentorship ? 'preferences.opt_in_header' : 'preferences.no_skills_to_mentor'}
                    />
                  </CardSection>
                  {hasSkillsMentorship ? (
                    <CheckedSkillsGrid
                        checked
                        skills={skills}
                        onClick={handleMentorshipOff}
                        disabled={updatePending || isNil(skills)}
                    />
                  ) : undefined}
                </>
              ) : undefined}
              {hasSkillsToMentor || !hasSkillsMentorship ? (
                <>
                  <CardSection top={hasSkillsToMentor} bottom={!hasSkillsToMentor}>
                    <CardSubheader
                        text={hasSkillsToMentor
                          ? (hasSkillsMentorship && 'preferences.other_suggested_skills') || 'preferences.suggested_skills'
                          : 'preferences.no_suggested_skills'}
                    />
                  </CardSection>
                  {hasSkillsToMentor ? (
                    <CheckedSkillsGrid
                        skills={skillsToMentor}
                        onClick={handleMentorshipOn}
                        disabled={updatePending || isNil(skillsToMentor)}
                    />
                  ) : undefined}
                </>
              ) : undefined}
            </Collapse>
          ) : undefined}
          <ActionFailedAlert
              message="preferences.update_error"
              open={updateFailed}
          />
        </>
     )}
    </ContentCard>
  );
};

export default memo(Preferences);
