import { forwardRef, memo, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
// Skillmore UI Components
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
// local imports
import useCustomerSettings from '../config/customer';
import { toggleReducer } from '../helpers/reducers';

const logoUrl = `/customers/${window.CUSTOMER ? `${window.CUSTOMER}/` : ''}logo.svg`;

type CustomerLogoProps = {
  logo?: string;
  width?: string;
  height?: string;
  // for Storybook only
  logoError?: boolean;
};

const CustomerLogoPropTypes = {
  logo: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  logoError: PropTypes.bool
};

const CustomerLogo = forwardRef<HTMLDivElement, CustomerLogoProps>(({
  logo = logoUrl,
  width,
  height,
  logoError = false
}, ref) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();

  const { LOGO_WIDTH, LOGO_HEIGHT } = useCustomerSettings();

  const [error, toggleError] = useReducer(toggleReducer, logoError);

  const brandName = useMemo(() => formatMessage({ id: 'header.brand' }), [formatMessage]);

  const sx = useMemo(() => ({
    minWidth: width || LOGO_WIDTH,
    width: width || LOGO_WIDTH,
    maxHeight: height || LOGO_HEIGHT
  }), [LOGO_HEIGHT, LOGO_WIDTH, width, height]);

  return error ? (
    <BoxTypography
        ref={ref}
        flexGrow={1}
        display="flex"
        alignItems="center"
        color="secondary.main"
        sx={sx}
        variant="h1"
    >
      {brandName}
    </BoxTypography>
  ) : (
    <Box ref={ref} sx={sx}>
      <img src={logo} onError={toggleError} alt={brandName}/>
    </Box>
  );
});

CustomerLogo.displayName = 'CustomerLogo';

CustomerLogo.propTypes = CustomerLogoPropTypes;

export default memo(CustomerLogo);
