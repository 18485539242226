/* eslint-disable max-lines */
import { memo, forwardRef, useCallback, useMemo } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import find from 'lodash/find';
import transform from 'lodash/transform';
import isSafeInteger from 'lodash/isSafeInteger';
import toSafeInteger from 'lodash/toSafeInteger';
import { FormattedDate, FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CircularProgress from '@mui/material/CircularProgress';
// Material Icon imports
import DeleteIcon from '@mui/icons-material/DeleteOutline';
// Skillmore UI Components
import { fontWeightMedium } from '@empathco/ui-components/src/styles/themeOptions';
import AccountCircleAlt from '@empathco/ui-components/src/icons/AccountCircleAlt';
import { defaultDateOptions, shortDateOptions } from '@empathco/ui-components/src/common/intl';
import { getCurrentUtc, getUtcFromISO, getJsDateFromISO } from '@empathco/ui-components/src/helpers/datetime';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import TagLabel from '@empathco/ui-components/src/elements/TagLabel';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import MatchIndicator from '@empathco/ui-components/src/elements/MatchIndicator';
// local imports
import {
  BookingStatus, MyOpportunityStatus, OpportunitySkillStatus, OpportunityStatus, SkillWithLevel,
  Employee, MyOpportunity, Opportunity, OpportunityMatch
} from '../graphql/types';
import { Skill, SKILL_LEVEL_MIN } from '../models/skill';
import useModels, { getSkillCurrentLevel } from '../helpers/models';
import EmployeeName from '../elements/EmployeeName';
import TbItemChip from '../elements/TbItemChip';
// SCSS imports
import { card, header, avatar, details, skillChip, footer } from './OpportunityBookingCard.module.scss';

type OpportunityBookingCardProps = {
  supervisor?: boolean;
  item: OpportunityMatch | MyOpportunity;
  opportunity?: Opportunity | null;
  disabled?: boolean | null;
  // manager & employee
  removePending?: boolean | null;
  requestPending?: boolean | null;
  rejectPending?: boolean | null;
  // manager
  route?: string | null;
  readOnly?: boolean;
  onRequest?: (oppMatch: OpportunityMatch, reject?: boolean) => void;
  onRemove?: (matchId: number) => void;
  onDelete?: (matchId: number, bookingId?: number, shortlisted?: boolean) => void;
  onOpportunityStatus?: (matchId: number, status: OpportunityStatus) => void;
  statusPending?: boolean | null;
  deletePending?: boolean | null;
  // employee
  onBookingRequest?: (opp: MyOpportunity, reject?: boolean) => void;
  onRemoveApplication?: (opp: MyOpportunity) => void;
  onEndOpportunity?: (opp: MyOpportunity) => void;
};

const OpportunityBookingCardPropTypes = {
  supervisor: PropTypes.bool,
  item: PropTypes.object.isRequired as Validator<OpportunityMatch>,
  opportunity: PropTypes.object as Validator<Opportunity>,
  disabled: PropTypes.bool,
  // manager & employee
  removePending: PropTypes.bool,
  requestPending: PropTypes.bool,
  rejectPending: PropTypes.bool,
  // manager
  route: PropTypes.string,
  readOnly: PropTypes.bool,
  onRequest: PropTypes.func,
  onRemove: PropTypes.func,
  onDelete: PropTypes.func,
  onOpportunityStatus: PropTypes.func,
  statusPending: PropTypes.bool,
  deletePending: PropTypes.bool,
  // employee
  onBookingRequest: PropTypes.func,
  onRemoveApplication: PropTypes.func,
  onEndOpportunity: PropTypes.func
};

// eslint-disable-next-line complexity, max-lines-per-function, max-statements
const OpportunityBookingCard = forwardRef<HTMLDivElement, OpportunityBookingCardProps>(({
  supervisor = false,
  item,
  opportunity: opportunityProp,
  disabled: parentDisabled = false,
  // manager & employee
  removePending = false,
  requestPending = false,
  rejectPending = false,
  // manager
  route,
  readOnly = false,
  onRequest,
  onRemove,
  onDelete,
  onOpportunityStatus,
  statusPending = false,
  deletePending = false,
  // employee
  onBookingRequest,
  onRemoveApplication,
  onEndOpportunity
}, ref) => {
  const { getLocationStr } = useModels();
  const { id, match_rate, growth_rate, booking, employee_skills } = item;
  const { status, employee_feedback, employee_requested_at, manager_requested_at, confirmed_at } = booking || {};
  // Manager
  const { employee, owner_status, owner_status_updated_at } = (supervisor && item as OpportunityMatch) || {};
  const { current_job, location, manager } = employee || {};
  const { title } = current_job || {};
  // Employee
  const opportunity = (!supervisor && (item as MyOpportunity)?.opportunity) || opportunityProp;
  const {
    title: oppTitle, status: oppStatus, duration_value, duration_unit, start_date,
    sidegig, onsite, timezone_minutes, timestamp, published_at, started_at, archived_at,
    location: oppLocation, owner, skills
  } = opportunity || {};

  const [
    requiredSkillsTotal, skillsGrowthTotal,
    employeeSatisfied, employeeUnsatisfied,
    employeeGrowth, employeeNoGrowth
  ] = useMemo(() => transform(skills || [], (result, skill) => {
      result[skill.status === OpportunitySkillStatus.required ? 0 : 1] += 1;
      const emplSkill = find(employee_skills, ['id', skill.id]) || {
        id: skill.id,
        abbr: skill.abbr,
        title: skill.title,
        current_level: 0,
        inferred_level: 0,
        is_inference_newer: false
      } as SkillWithLevel;
      const isReq = skill.status === OpportunitySkillStatus.required;
      const { skill_proficiency_level: expected_level } = skill;
      const lvl = getSkillCurrentLevel(emplSkill) || SKILL_LEVEL_MIN;
      const is_satisfied = isReq ? lvl >= expected_level : lvl < expected_level;
      result[isReq ? (is_satisfied && 2) || 3 : (is_satisfied && 4) || 5]
        .push({ ...emplSkill, expected_level, is_satisfied });
    }, [
      0 as number,
      0 as number,
      [] as SkillWithLevel[],
      [] as SkillWithLevel[],
      [] as SkillWithLevel[],
      [] as SkillWithLevel[]
    ]), [skills, employee_skills]);

  const disabled = parentDisabled || removePending || requestPending || rejectPending || statusPending || deletePending;

  // Employee's Application is waiting Manager's Booking Request or Decline of the Application
  const isActiveApplication = status === BookingStatus.employee_requested;
  // Employee applied for this opprtunity (regardless of the application status at this time)
  const isApplicant = isActiveApplication || (employee_requested_at && status !== BookingStatus.employee_rejected)
    ? true : undefined;
  // Manager Short-listed Employee
  const isMgrShortlisted = supervisor && owner_status === MyOpportunityStatus.shortlist;
  // Manager's Booking Request is waiting Employee's Confirmation or Decline
  const isActiveRequest = status === BookingStatus.manager_requested;
  const isDeclinedRequest = status === BookingStatus.employee_rejected;
  // Manager sent Booking Request to Employee (regardless of the request status at this time)
  const isRequested = isActiveRequest || manager_requested_at ? true : undefined;
  // Opportunity is Confirmed
  const isBooked = status === BookingStatus.confirmed;
  // Opportunity is Started
  const isStarted = isBooked && oppStatus === OpportunityStatus.started;
  // Employee: Opportunity duration is elapsed
  const started = started_at ? getUtcFromISO(started_at) : undefined;
  const isExpired = isStarted && (duration_value || 0) >= 1 && started?.isValid && (
    started.plus({ months: duration_value }) <= getCurrentUtc()
  );

  // Manager: can send Booking Request to Employee (if there is no booking at all or there is no active Booking Request)
  const mgrCanRequest = supervisor && onRequest && !readOnly &&
    (!status || (!isBooked && status !== BookingStatus.manager_requested));
  // Manager: can Decline Application of Employee (if there is active Employee's Application)
  const mgrCanReject = supervisor && onRequest && !readOnly && isActiveApplication;
  // Manager: can Start Opportunity (if the booking is Confirmed and the Opportunity is just Published, but not Started yet)
  const mgrCanStart = supervisor && onOpportunityStatus && isBooked && oppStatus === OpportunityStatus.published;
  // Manager: can Archive (End) Opportunity (if the booking is Confirmed and the Opportunity is Started)
  const mgrCanArchive = supervisor && onOpportunityStatus && isStarted;
  // Manager: can remove Employee from Short-listed (if there is no Booking Request and the booking is not Confirmed)
  const mgrCanRemove = isMgrShortlisted && onRemove && !readOnly && !isActiveRequest && !isBooked;
  // Manager: can Delete Opportunity
  const mgrCanDelete = supervisor && onDelete && (readOnly || isDeclinedRequest) && !mgrCanRemove && !isBooked;

  // Employee: can Confirm or Decline Manager's Booking Request
  const emplCanRespond = !supervisor && onBookingRequest && isActiveRequest;
  // Employee: can End Opportunity (if the booking is Confirmed and Opportunity is Started)
  const emplCanEnd = !supervisor && onEndOpportunity && isStarted;

  // Status Message: Manager's Booking Request status or Employee/Manager Opportunity End message
  const statusMessage = (supervisor && onRequest && !readOnly && (
    (isActiveRequest && 'opportunities.booking.request_sent') ||
    (isDeclinedRequest && 'opportunities.booking.request_declined')
  )) || (isExpired && 'opportunities.booking.expired');
  const isStatusWarning = isDeclinedRequest;
  const isStatusPrompt = isExpired;

  // Remove button in status bar is visible:
  const canRemove = mgrCanRemove || mgrCanDelete ||
    // Employee: can withdraw Application (if Employee has applied before and Manager did not sent a Booking Request)
    (!supervisor && isActiveApplication && onRemoveApplication && !isRequested);

  // Decline button is visible:
  const canDecline = mgrCanReject || emplCanRespond;
  // Confirm/Request/Start button is visible:
  const canConfirm = (supervisor && (mgrCanRequest || Boolean(statusMessage) || mgrCanStart || mgrCanArchive)) ||
    emplCanRespond || emplCanEnd;

  const handleReject = useCallback(() => {
    if (supervisor) {
      if (item) onRequest?.(item as OpportunityMatch, true);
    } else if (item) {
      onBookingRequest?.(item as MyOpportunity, true);
    }
  }, [item, supervisor, onRequest, onBookingRequest]);

  const handleRequest = useCallback(() => {
    if (!item) return;
    if (supervisor) {
      // Manager
      if (mgrCanArchive) onOpportunityStatus(item.id, OpportunityStatus.archived);
      else if (mgrCanStart) onOpportunityStatus(item.id, OpportunityStatus.started);
      else onRequest?.(item as OpportunityMatch, false);
    } else
    // Employee
    if (emplCanEnd) {
      onEndOpportunity(item as MyOpportunity);
    } else {
      onBookingRequest?.(item as MyOpportunity, false);
    }
  }, [
    item, mgrCanArchive, mgrCanStart, emplCanEnd, supervisor,
    onRequest, onBookingRequest, onOpportunityStatus, onEndOpportunity
  ]);

  const handleRemove = useCallback(() => {
    if (supervisor) {
      if (id) onRemove?.(id);
    } else if (item) {
      onRemoveApplication?.(item as MyOpportunity);
    }
  }, [id, item, supervisor, onRemove, onRemoveApplication]);

  const handleDelete = useCallback(() => {
    if (supervisor) onDelete?.(
      item.id,
      item.booking?.status === BookingStatus.deleted ? undefined : item.booking?.id,
      (item as OpportunityMatch).owner_status === MyOpportunityStatus.shortlist
    );
  }, [item, supervisor, onDelete]);

  return (
    <Card ref={ref} elevation={9} className={card}>
      {isMgrShortlisted || isApplicant || canRemove ? (
        <CardSection dark className={header}>
          {isApplicant ? (
            <Box pr={1.5}>
              <TagLabel
                  small
                  variant="outlined"
                  title={supervisor ? 'opportunities.matches.applicant' : 'opportunities.applied'}
              />
            </Box>
          ) : undefined}
          {isMgrShortlisted ? (
            <Box pr={1}>
              <TagLabel small variant="outlined" title="opportunities.matches.short_listed"/>
            </Box>
          ) : undefined}
          {canRemove ? (
            <Button
                color={mgrCanDelete ? 'error' : 'secondary'}
                variant="text"
                size="small"
                disabled={disabled ? true : undefined}
                onClick={mgrCanDelete ? handleDelete : handleRemove}
                startIcon={removePending || deletePending ? <CircularProgress size={18} color="inherit"/>
                  : (mgrCanDelete && <DeleteIcon/>) || undefined}
            >
              <FormattedMessage
                  id={supervisor ? (mgrCanDelete && 'opportunities.button.delete') || 'common.button.remove'
                    : 'opportunities.button.remove'}
              />
            </Button>
          ) : undefined}
        </CardSection>
      ) : undefined}
      <CardSection className={details}>
        {supervisor ? <AccountCircleAlt color="inherit" className={avatar}/> : undefined}
        {supervisor || opportunity ? (
          <Box pl={1.5} pb={4} maxWidth={supervisor ? undefined : '24rem'}>
            {supervisor ? (
              <>
                <EmployeeName
                    variant="h4"
                    employee={employee as Employee}
                    manager
                    route={route}
                />
                <BoxTypography variant="body2">
                  {title}
                </BoxTypography>
                <BoxTypography variant="body2">
                  {getLocationStr(location) || <FormattedMessage id="employees.not_available"/>}
                </BoxTypography>
                {manager ? (
                  <>
                    <BoxTypography variant="h6" pt={1.5}>
                      <FormattedMessage id="opportunities.booking.manager"/>
                    </BoxTypography>
                    <EmployeeName
                        variant="body2"
                        employee={manager as Employee}
                        manager
                    />
                  </>
                ) : undefined}
              </>
            ) : (
              <>
                <BoxTypography
                    variant="body2"
                    color="info.caption"
                    fontStyle="italic"
                    pb={2.5}
                >
                  <FormattedMessage
                      id="opportunities.timestamp"
                      values={{
                        status: oppStatus,
                        date: timestamp ? (
                          <FormattedDate
                              value={getJsDateFromISO(timestamp)}
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...supervisor ? defaultDateOptions : shortDateOptions}
                          />
                        ) : null,
                        update: null,
                        owner: owner ? (
                          <EmployeeName
                              employee={owner}
                              manager
                              disabled={disabled ? true : undefined}
                          />
                        ) : '—'
                      }}
                  />
                </BoxTypography>
                <BoxTypography variant="subtitle1">
                  {oppTitle}
                </BoxTypography>
                <BoxTypography variant="body2" pt={2.25}>
                  {getLocationStr(oppLocation) || <FormattedMessage id="opportunities.location_not_specified"/>}
                  <FormattedMessage
                      id="opportunities.location_options"
                      values={{ onsite, timezone: isSafeInteger(timezone_minutes) ? timezone_minutes : null, style: 'dash' }}
                  />
                </BoxTypography>
                <BoxTypography variant="body2" pt={1.75}>
                  <FormattedMessage
                      id="opportunities.duration"
                      values={{ duration: duration_value, unit: duration_unit, sidegig, withTitle: true }}
                  />
                </BoxTypography>
                <BoxTypography variant="body2" pt={0.5}>
                  <FormattedMessage
                      id="opportunities.start_date"
                      values={{
                        date: start_date ? getJsDateFromISO(start_date) : null,
                        withTitle: null
                      }}
                  />
                </BoxTypography>
              </>
            )}
          </Box>
        ) : undefined}
        {[
          {
            msgId: 'skills_match',
            isGrowth: false,
            rate: match_rate,
            total: requiredSkillsTotal,
            satisfied: employeeSatisfied,
            unsatisfied: employeeUnsatisfied
          },
          {
            msgId: 'skills_growth',
            isGrowth: true,
            rate: growth_rate,
            total: skillsGrowthTotal,
            satisfied: employeeGrowth,
            unsatisfied: employeeNoGrowth
          }
        ].map(({ msgId, isGrowth, rate, total, satisfied, unsatisfied }) => (
          <Box key={msgId} pl={4} pb={4} flex="1 1 0" display="flex" justifyContent="flex-end">
            <Box pr={2}>
              <Box pt={0.625} display="flex" alignItems="baseline">
                <BoxTypography variant="subtitle2" fontWeight={fontWeightMedium}>
                  <FormattedMessage id={`opportunities.booking.${msgId}`}/>
                </BoxTypography>
                <BoxTypography variant="body2" color="text.secondary" fontStyle="italic" pl={2}>
                  <FormattedMessage
                      id="opportunities.matched_skills"
                      values={{ total, satisfied: size(satisfied) }}
                  />
                </BoxTypography>
              </Box>
              {map([...satisfied, ...unsatisfied], (skill) => (
                <TbItemChip
                    key={skill.id}
                    item={skill as Skill}
                    disabled={disabled ? true : undefined}
                    withExpectedLevel={!isGrowth || !skill.is_satisfied}
                    withGrowthLevel={Boolean(isGrowth && skill.is_satisfied)}
                    satisfied={isGrowth ? undefined : skill.is_satisfied}
                    className={skillChip}
                />
              ))}
            </Box>
            <Box pt={0.5}>
              <MatchIndicator
                  value={toSafeInteger(rate)}
                  variant={isGrowth ? 'planned' : undefined}
                  label={isGrowth ? 'opportunities.growth_rate_value' : undefined}
              />
            </Box>
          </Box>
        ))}
      </CardSection>
      <CardSection className={footer}>
        <BoxTypography variant="h4" pl={9.5} pb={3} color="text.secondary">
          <FormattedMessage id="opportunities.booking.timeline"/>
        </BoxTypography>
        <Stepper
            alternativeLabel
            activeStep={(isBooked && oppStatus === OpportunityStatus.archived && 5) ||
              (isStarted && 4) ||
              (isActiveRequest && 2) ||
              (isBooked && 3) || 1}
        >
          {[
            {
              stepId: supervisor ? (isMgrShortlisted && 3) || 1 : (isApplicant && 2) || 1,
              // eslint-disable-next-line no-nested-ternary
              dt: supervisor
                ? isMgrShortlisted ? owner_status_updated_at : published_at
                : isApplicant ? employee_requested_at : published_at
            },
            { stepId: 4, dt: manager_requested_at },
            { stepId: 5, dt: confirmed_at },
            { stepId: 6, dt: isBooked ? started_at : undefined },
            { stepId: 7, dt: isBooked ? archived_at : undefined }
          ].map(({ stepId, dt }) => (
            <Step key={stepId}>
              <StepLabel
                  optional={dt ? (
                    <span>
                      <FormattedDate
                          value={getJsDateFromISO(dt)}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...shortDateOptions}
                      />
                    </span>
                  ) : undefined}
              >
                <FormattedMessage id={`opportunities.booking.timeline.step${stepId}`}/>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {employee_feedback ? (
          <>
            <BoxTypography variant="subtitle2" fontWeight={fontWeightMedium} pt={5} px={5} color="text.secondary">
              <FormattedMessage id="opportunities.booking.feedback.title" values={{ supervisor }}/>
            </BoxTypography>
            <BoxTypography variant="body1" color="info.caption" fontWeight={fontWeightMedium} py={1} px={5}>
              {employee_feedback}
            </BoxTypography>
          </>
        ) : undefined}
        {canConfirm || canDecline ? (
          <Box pt={2} display="flex" alignItems="center" justifyContent="flex-end">
            {statusMessage ? (
              <BoxTypography
                  pr={2}
                  color={(isStatusWarning && 'error.main') || (isStatusPrompt && 'info.caption') || undefined}
                  variant="body1"
                  fontStyle="italic"
                  fontWeight={isStatusPrompt ? fontWeightMedium : undefined}
              >
                <FormattedMessage id={statusMessage}/>
              </BoxTypography>
            ) : undefined}
            {canDecline ? (
              <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleReject}
                  disabled={disabled || (!mgrCanReject && !emplCanRespond) ? true : undefined}
                  startIcon={rejectPending ? <CircularProgress size={18} color="inherit"/> : undefined}
              >
                <FormattedMessage id={supervisor ? 'opportunities.button.reject' : 'opportunities.button.decline'}/>
              </Button>
            ) : undefined}
            {canDecline && canConfirm ? <Box width="5%"/> : undefined}
            {canConfirm ? (
              <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  onClick={handleRequest}
                  disabled={disabled || (!mgrCanRequest && !mgrCanStart && !mgrCanArchive && !emplCanRespond && !emplCanEnd)
                    ? true : undefined}
                  startIcon={requestPending || statusPending
                    ? <CircularProgress size={18} color="inherit"/> : undefined}
              >
                <FormattedMessage
                    id={supervisor
                      ? (mgrCanArchive && 'opportunities.booking.button.archive') ||
                        (mgrCanStart && 'opportunities.booking.button.start') ||
                        // (isDeclinedRequest && '... ? resend booking request ? ...') ||
                        'opportunities.booking.button.manager_request'
                      : (emplCanEnd && 'opportunities.booking.button.end') ||
                        'opportunities.button.confirm'}
                />
              </Button>
            ) : undefined}
          </Box>
        ) : undefined}
      </CardSection>
    </Card>
  );
});

OpportunityBookingCard.displayName = 'OpportunityBookingCard';

OpportunityBookingCard.propTypes = OpportunityBookingCardPropTypes;

export default memo(OpportunityBookingCard);
