import { forwardRef, memo, useMemo, type FunctionComponent, type HTMLProps } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
// Material UI imports
import { type BoxProps, type SystemProps } from '@mui/system/Box';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// SCSS imports
// Media and SCSS imports
import copyrightLogoImage from '../images/skillmore.png';
import { copyright, copyrightLogo } from './Copyright.module.scss';

const BoxLink: FunctionComponent<SystemProps & HTMLProps<HTMLAnchorElement>> = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Box component="a" {...props as BoxProps}/>
);

const Copyright = forwardRef<HTMLAnchorElement, {}>((_props, ref) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();
  const link = useMemo(() => formatMessage({ id: 'footer.copyright.powered_by_link' }), [formatMessage]);
  const alt = useMemo(() => formatMessage({ id: 'footer.copyright.company_name' }), [formatMessage]);
  return (
    <BoxLink
        ref={ref}
        mt={2}
        mb={4}
        display="flex"
        alignItems="center"
        textAlign="center"
        justifyContent="center"
        href={link}
        className={copyright}
        target="_blank"
        rel="noopener noreferrer"
    >
      <Typography variant="caption" color="textSecondary">
        <FormattedMessage id="footer.copyright.powered_by"/>
      </Typography>
      <img src={copyrightLogoImage} className={copyrightLogo} alt={alt}/>
    </BoxLink>
  );
});

Copyright.displayName = 'Copyright';

export default memo(Copyright);
