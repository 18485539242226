import {
  memo, useCallback, useContext, useMemo, useState, useLayoutEffect,
  type ReactNode, type FunctionComponent
} from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// Skillmore UI Components
import { fontWeightMedium } from '@empathco/ui-components/src/styles/themeOptions';
import { mapChunks } from '@empathco/ui-components/src/helpers/intl';
import StandardLink from '@empathco/ui-components/src/elements/StandardLink';
import ActionSucceededMessage from '@empathco/ui-components/src/elements/ActionSucceededMessage';
// local imports
import { hasLeadership, isManager, isEmployee, isContractor } from '../models/user';
import useCustomerSettings, { CustomerTicker } from '../config/customer';
import { getTicker } from '../helpers/customer';
import { openMessage, setOpenMessage } from '../helpers/storage';
import { GlobalContext } from '../context/global';
// SCSS imports
import { tckr, warn, ln } from './AppBarTicker.module.scss';

type AppBarTickerProps = {
  tickers?: CustomerTicker[];
  atJobScreen?: boolean;
}

const AppBarTickerPropTypes = {
  tickers: PropTypes.array,
  atJobScreen: PropTypes.bool
};

const AppBarTicker: FunctionComponent<AppBarTickerProps> = ({
  tickers: parentTickers,
  atJobScreen = false
}) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();

  const { CUSTOMER_TICKERS } = useCustomerSettings();
  const tickers = parentTickers || CUSTOMER_TICKERS;

  const { user: { data: user } } = useContext(GlobalContext);
  const { code, first_name: name } = user || {};
  const supv = isManager(user);
  const leader = hasLeadership(user);
  const employee = isEmployee(user);
  const contractor = isContractor(user);
  const { id, ticker, popup, is_warning, show_on_job_screen } = useMemo(() =>
    getTicker(supv, leader, employee, contractor, tickers),
    [supv, leader, employee, contractor, tickers]
  );
  const tickerId = `ticker${id}|`;

  const shouldOpen = openMessage(tickerId, code);
  const [open, setOpen] = useState(false);
  useLayoutEffect(() => {
    if (shouldOpen) setOpen(true);
  }, [shouldOpen]);

  const handlePopupOpen = useCallback(() => setOpen(true), []);
  const handlePopupClose = useCallback(() => {
    setOpen(false);
    setOpenMessage(tickerId, code);
  }, [code, tickerId]);

  const isVisible = !atJobScreen || show_on_job_screen;

  const values = useMemo(() => isVisible && popup ? {
    name,
    // eslint-disable-next-line react/no-unstable-nested-components
    line: (chunks?: ReactNode | ReactNode[] | null): ReactNode => (
      <Typography
          align="center"
          variant="inherit"
          paragraph
          className={ln}
      >
        {mapChunks(chunks)}
      </Typography>
    ),
    // eslint-disable-next-line react/no-unstable-nested-components
    bold: (chunks?: ReactNode | ReactNode[] | null): ReactNode => (
      <Box fontWeight={fontWeightMedium} component="span">
        {mapChunks(chunks)}
      </Box>
    ),
    br: <br/>,
    link: popup.link ? (chunks?: ReactNode | ReactNode[] | null): ReactNode => (
      <StandardLink href={formatMessage({ id: popup.link as string, defaultMessage: popup.link as string })}>
        {mapChunks(chunks)}
      </StandardLink>
    ) : undefined,
    inline_link: popup.inline_link ? (chunks?: ReactNode | ReactNode[] | null): ReactNode => (
      <StandardLink href={formatMessage({ id: popup.inline_link as string, defaultMessage: popup.inline_link as string })}>
        {mapChunks(chunks)}
      </StandardLink>
    ) : undefined
  } : undefined, [name, popup, isVisible, formatMessage]);

  return isVisible && (ticker || popup) ? (
    <>
      {ticker ? (
        <Box
            p={1.75}
            textAlign="center"
            className={is_warning ? warn : tckr}
        >
          <StandardLink
              active
              variant="h4"
              text={ticker.text}
              link={ticker.link}
              onClick={ticker.link ? undefined : handlePopupOpen}
          />
        </Box>
      ) : undefined}
      {popup ? (
        <ActionSucceededMessage
            open={open}
            onOK={handlePopupClose}
            maxWidth="md"
            withClose
            disableTypography
            title={popup.title}
            message={popup.text}
            button={popup.button}
            link={popup.link}
            values={values}
        />
      ) : undefined}
    </>
  ) : null;
};

AppBarTicker.propTypes = AppBarTickerPropTypes;

export default memo(AppBarTicker);
